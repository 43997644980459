import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid,
    Typography,
    SvgIcon,
    Drawer,
    IconButton,
    Container,
    Button
} from '@material-ui/core';

import {
    KeyboardArrowRight,
    KeyboardArrowLeft,
    Close
} from '@material-ui/icons';
import MUICarousel from 'react-material-ui-carousel';
import { useStyles } from '../styles';
import QsiseExecuteWrapper from 'qsise-execute';
import { getQsiseDashboard } from '../../../actions/qSiseDashboardAction';
import { getQsiseSinister } from '../../../actions/qSiseSinisterAction';
import ButtonAction from '../../../components/buttonAction';
import {
    ID_Q_SISE_CARTERA,
    SISE_URL,
    ID_Q_SISE_FEES_PAYMENTS,
    quotationUrlBase
} from '../../../utils/urls';
import CardInsured from '../../../components/widgets/cards/insured';
import CardPay from '../../../components/widgets/cards/payCustom';
import CardComplaints from '../../../components/widgets/cards/complaintsAlternative';
import { general } from '../../../utils/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { getDataUserLoggeIn } from '../../../actions/userInfoActions';
import { getPaymentMethods } from '../../../actions/paymentMethodsActions';
import { getAllRiskType } from '../../../actions/riskTypeActions';
import { BannersNews } from 'news-list';
import { Alert } from '@material-ui/lab';
import { getAllActions } from '../../../actions/actions';
import { navigate } from '@reach/router';
import { getDataToken } from '../../../utils/index';

import ISinister from '../../../assets/images/icon_sinister_libra.png';

const Dashboard = () => {
    SwiperCore.use([Navigation, Pagination]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const token = useSelector((state) => state.auth.token);
    const paymentMethods = useSelector((state) => state.paymentMethods);
    const [openDetails, setOpenDetails] = useState(false);
    const [openPaymentsDetails, setOpenPaymentsDetails] = useState(false);
    const [policy, setPolicy] = useState(null);
    const config = useSelector((state) => state.userConfig.data);
    const { loading: loadingDashboard, ...qSiseDashboard } = useSelector(
        (state) => state.qSiseDashboard
    );
    const qSiseSinister = useSelector((state) => state.qSiseSinister);
    const userInfo = useSelector((state) => state.userInfo);
    const auth = useSelector((state) => state.auth);
    const actions = useSelector((state) => state.actions);
    const risktype = useSelector((state) => state.risktype);
    const [, setDataDashboard] = useState(null);
    const [policies, setPolicies] = useState(null);
    const [dataSinister, setDataSinister] = useState(null);
    const [isHistory, setIsHistory] = useState(false);
    const [selectedPolice, setSelectedPolice] = useState(null);
    const swiperPolice = useRef(null);
    const [dataToken, setDataToken] = useState();

    const handlePolicyDrawer = (policyNumber) => {
        setPolicy(policyNumber);
        setOpenDetails(true);
    };

    const handlePaymentDrawer = (policyNumber) => {
        setPolicy(policyNumber);
        setOpenPaymentsDetails(true);
    };

    const executeInitQsiseDashboard = async () => {
        await dispatch(getQsiseDashboard(token));
    };

    const executeInitQsiseSinister = async () => {
        await dispatch(getQsiseSinister(token));
    };

    useEffect(() => {
        if (Object.keys(qSiseDashboard.data).length === 0) {
            executeInitQsiseDashboard();
        }
        if (Object.keys(qSiseSinister.data).length === 0) {
            executeInitQsiseSinister();
        }
        if (auth && !auth.token) {
            dispatch(getDataUserLoggeIn(auth?.token));
        }
        if (actions && actions.data && !actions.data.length) {
            dispatch(getAllActions(token, { active: true }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.token]);

    useEffect(() => {
        if (
            auth &&
            auth.token &&
            paymentMethods &&
            paymentMethods.data &&
            !paymentMethods.data.length
        ) {
            dispatch(getAllRiskType(auth?.token));
            dispatch(getPaymentMethods(auth?.token));
            const _dataToken = getDataToken(auth?.token);
            setDataToken(_dataToken);
        }
    }, [auth.token]);

    useEffect(() => {
        if (qSiseDashboard?.data?.data) {
            setDataDashboard(qSiseDashboard?.data?.data?.listaPolizas?.result);
            setPolicies(qSiseDashboard?.data?.data?.listaPolizas?.result);
        }
        if (qSiseSinister?.data?.data) {
            setDataSinister(qSiseSinister?.data?.data?.listaSiniestros?.result);
        }
    }, [qSiseDashboard, qSiseSinister]);

    const policiesSort = policies?.sort((a, b) => {
        if (a.estado === 'VENCIDA' && b.estado !== 'VENCIDA') {
            return -1;
        }
        if (
            a.estado === 'VENCIDA' &&
            b.estado === 'VENCIDA' &&
            moment(
                moment(a.proxima_cuota_vencimiento).format('YYYY-MM-DD')
            ).isBefore(moment(b.proxima_cuota_vencimiento).format('YYYY-MM-DD'))
        ) {
            return -1;
        }

        if ( 
            a.estado !== 'VENCIDA' &&
            b.estado !== 'VENCIDA' &&
            a.estado === 'PENDIENTE' &&
            b.estado === 'PENDIENTE' &&
            moment(
                moment(a.proxima_cuota_vencimiento).format('YYYY-MM-DD')
            ).isBefore(moment(b.proxima_cuota_vencimiento).format('YYYY-MM-DD'))
        ) {
            return -1;
        }

        return 0;
    });

    // toggleHistory : sirve para cambiar de state del toogle que renderiza entre el historico y la lista de polizas
    const toggleHistory = () => {
        setIsHistory(!isHistory);
    };

    // onActiveAction: sirve para actualizar el state de selectedPolice y poder identificar que poliza se esta observando en pagos
    const onActiveAction = (item) => {
        setSelectedPolice(item?.id_pv);
    };
    // openNewTab: permite abrir una nueva ventana con una url
    const openNewTab = (url) => {
        window.open(url, '_blank');
    };

    // query filter visibility news
    const query = { query: 'visibility=/1757/i' };

    return (
        <>
            {/* Banner Slider */}
            <BannersNews
                token={token}
                url={quotationUrlBase}
                query={query}
                userInfo={userInfo}
            />
            <Container className={classes.content} maxWidth={'lg'}>
                {/* Cards Policies */}
                <Grid container spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item xs={6} md={6}>
                        <Typography className={classes.subtitle}>
                            {isHistory
                                ? `Pólizas No vigentes`
                                : `Pólizas Vigentes`}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        xs={6}
                        md={6}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={toggleHistory}
                        >
                            {isHistory
                                ? `Pólizas Vigentes`
                                : `Pólizas No vigentes`}
                        </Button>
                    </Grid>
                </Grid>

                {!loadingDashboard &&
                policies &&
                policies?.filter((e) =>
                    isHistory
                        ?  moment(
                                moment(new Date()).format('YYYY-MM-DD')
                           ).isAfter(moment(e?.fecha_fin).format('YYYY-MM-DD'))
                        : moment(
                            moment(new Date()).format('YYYY-MM-DD')
                          ).isBefore(moment(e?.fecha_fin).format('YYYY-MM-DD'))
                ).length === 0 ? (
                    <Alert severity="info">Sin pólizas para mostrar</Alert>
                ) : (
                    <div className={classes.rootSwiper}>
                        <div className="swiper-left-arrow">
                            <KeyboardArrowLeft color="primary" />
                        </div>
                        <Swiper
                            ref={swiperPolice}
                            className={classes.sliderInsured}
                            spaceBetween={21}
                            slidesPerGroupSkip={1}
                            slidesOffsetBefore={0}
                            navigation={{
                                nextEl: '.swiper-right-arrow',
                                prevEl: '.swiper-left-arrow',
                                clickable: true
                            }}
                            breakpoints={{
                                700: {
                                    slidesPerView: 2,
                                    slidesOffsetBefore: 0
                                },
                                1150: {
                                    slidesPerView: 4,
                                    slidesOffsetBefore: 0
                                }
                            }}
                            modules={[Navigation, Pagination]}
                        >
                            {loadingDashboard &&
                                Array(4)
                                    .fill(true)
                                    .map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <CardInsured loading={item} />
                                        </SwiperSlide>
                                    ))}
                            {!loadingDashboard &&
                                policies &&
                                policies.length &&
                                policies
                                    ?.filter((e) =>
                                        isHistory ?  
                                          moment(
                                                moment(new Date()).format('YYYY-MM-DD')
                                           ).isAfter(moment(e?.fecha_fin).format('YYYY-MM-DD'))
                                        : moment(
                                            moment(new Date()).format('YYYY-MM-DD')
                                          ).isBefore(moment(e?.fecha_fin).format('YYYY-MM-DD'))
                                    )
                                    .map((policy, index) => (
                                        <SwiperSlide key={index}>
                                            <CardInsured
                                                risktype={risktype.data.find(
                                                    (e) =>
                                                        e.codeBE ===
                                                        policy.codigo_ramo
                                                )}
                                                selected={
                                                    policy?.id_pv ===
                                                    selectedPolice
                                                }
                                                isHistory={isHistory}
                                                policy={policy}
                                                handleOpenDrawer={() =>
                                                    handlePolicyDrawer(
                                                        policy.id_pv
                                                    )
                                                }
                                            />
                                        </SwiperSlide>
                                    ))}
                        </Swiper>
                        <div className="swiper-right-arrow">
                            <KeyboardArrowRight color="primary" />
                        </div>
                    </div>
                )}

                {/* Pagos y Siniestros pendientes */}
                <div style={{ overflow: 'hidden', marginTop: 28 }}>
                    <Grid container spacing={2}>
                        {!loadingDashboard && policies && policies.length > 0 && (
                            <Grid item xs={12} md={6}>
                                <Typography className={classes.subtitle}>
                                    Detalles de vencimiento
                                </Typography>
                                {policiesSort && policiesSort.length && (
                                    <MUICarousel
                                        classes={{
                                            root: classes.carouselComplaint
                                        }}
                                        NextIcon={<KeyboardArrowRight />}
                                        PrevIcon={<KeyboardArrowLeft />}
                                        navButtonsProps={{
                                            style: {
                                                padding: 0,
                                                backgroundColor: 'transparent',
                                                color: '#979797'
                                            }
                                        }}
                                        navButtonsAlwaysVisible={true}
                                        cycleNavigation={false}
                                        indicators={false}
                                        autoPlay={false}
                                        onChange={(now) =>
                                            onActiveAction(policiesSort[now])
                                        }
                                    >
                                        {policiesSort.map((policy, key) => (
                                            <SwiperSlide key={key}>
                                                <CardPay
                                                    policy={policy}
                                                    handleOpenDrawer={() =>
                                                        handlePaymentDrawer(
                                                            policy.id_pv
                                                        )
                                                    }
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </MUICarousel>
                                )}
                            </Grid>
                        )}

                        {!loadingDashboard &&
                            dataSinister &&
                            dataSinister.length > 0 && (
                                <Grid item xs={12} md={6}>
                                    <Typography className={classes.subtitle}>
                                        Siniestros pendientes
                                    </Typography>
                                    <MUICarousel
                                        classes={{
                                            root: classes.carouselComplaint
                                        }}
                                        NextIcon={<KeyboardArrowRight />}
                                        PrevIcon={<KeyboardArrowLeft />}
                                        navButtonsProps={{
                                            style: {
                                                padding: 0,
                                                backgroundColor: 'transparent',
                                                color: '#979797'
                                            }
                                        }}
                                        navButtonsAlwaysVisible={true}
                                        cycleNavigation={false}
                                        indicators={false}
                                        autoPlay={false}
                                    >
                                        {dataSinister.map((sinister, key) => (
                                            <CardComplaints
                                                sinister={sinister}
                                                position={key + 1}
                                                total={dataSinister.length}
                                                key={key}
                                                handleOpenDrawer={() =>
                                                    handlePolicyDrawer(
                                                        sinister.id_pv
                                                    )
                                                }
                                            />
                                        ))}
                                    </MUICarousel>
                                </Grid>
                            )}
                    </Grid>
                </div>
                {/* Button Call to Action*/}
                <div style={{ marginTop: 28 }}>
                    <Grid container spacing={2}>
                        {actions &&
                            actions.data &&
                            actions.data
                                .sort((prev, current) => prev - current)
                                .map((item, index) => (
                                    <Grid key={index} item xs={12} sm>
                                        <ButtonAction
                                            title={item?.name}
                                            onClick={() =>
                                                item.pathType === 'relative'
                                                    ? openNewTab(item.url)
                                                    : navigate(item.url)
                                            }
                                            isMenu={item?.isMenu}
                                            ariaControls={item?.ariaControls}
                                            listMenu={item?.listMenu}
                                            icon={
                                                item?.icon === 'info' ? (
                                                    <img
                                                        className={
                                                            classes.iconSinister
                                                        }
                                                        src={ISinister}
                                                        alt="icon"
                                                    />
                                                ) : (
                                                    <SvgIcon viewBox="0 0 20 20">
                                                        {general[item?.icon]}
                                                    </SvgIcon>
                                                )
                                            }
                                        />
                                    </Grid>
                                ))}
                    </Grid>
                </div>
                {policy && openDetails && (
                    <Drawer
                        ModalProps={{
                            disableBackdropClick: true,
                            hideBackdrop: true
                        }}
                        anchor="right"
                        open={openDetails}
                        classes={{
                            paper: classes.filterMenu
                        }}
                        onClose={() => {
                            setOpenDetails(false);
                        }}
                    >
                        <div className={classes.closeBtn}>
                            <IconButton
                                ariaClose-label="close details"
                                onClick={() => {
                                    setOpenDetails(!openDetails);
                                }}
                            >
                                <Close style={{ color: '#fff' }} />
                            </IconButton>
                        </div>
                        <QsiseExecuteWrapper
                            token={token}
                            endpoint={SISE_URL + '/api'}
                            qSiseID={ID_Q_SISE_CARTERA}
                            theme={config?.theme}
                            options={{
                                credentials: {
                                    username: 'iconnectance@sistran.com',
                                    password: 'digital'
                                },
                                variables: {
                                    policyId: policy,
                                    insuranceCode: dataToken?.user?.insuredCode
                                },
                                qSiseIndex: 0
                            }}
                        />
                    </Drawer>
                )}
                {/* Drawer para detalles de cuotas y pagos */}
                {policy && openPaymentsDetails && (
                    <Drawer
                        ModalProps={{
                            disableBackdropClick: true,
                            hideBackdrop: true
                        }}
                        anchor="right"
                        open={openPaymentsDetails}
                        classes={{
                            paper: classes.filterMenu
                        }}
                        onClose={() => {
                            setOpenPaymentsDetails(false);
                        }}
                    >
                        <div className={classes.closeBtn}>
                            <IconButton
                                ariaClose-label="close details"
                                onClick={() => {
                                    setOpenPaymentsDetails(
                                        !openPaymentsDetails
                                    );
                                }}
                            >
                                <Close style={{ color: '#fff' }} />
                            </IconButton>
                        </div>
                        <QsiseExecuteWrapper
                            token={token}
                            endpoint={SISE_URL + '/api'}
                            qSiseID={ID_Q_SISE_FEES_PAYMENTS}
                            theme={config?.theme}
                            options={{
                                credentials: {
                                    username: 'iconnectance@sistran.com',
                                    password: 'digital'
                                },
                                variables: {
                                    policyId: policy,
                                    insuranceCode: dataToken?.user?.insuredCode
                                },
                                qSiseIndex: 0
                            }}
                        />
                    </Drawer>
                )}
            </Container>
        </>
    );
};

export default React.memo(Dashboard);
