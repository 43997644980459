import { makeStyles } from '@material-ui/core';

export const cardComplaintsStyle = makeStyles((theme) => ({
    root: {
        position: 'relative',
        padding: 20,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        }
    },
    icon: {
        width: 70,
        height: 70
    },
    pagination: {
        position: 'absolute',
        right: 13,
        top: 12,
        fontSize: 12,
        color: theme.palette.typography && theme.palette.typography.main
    },
    dataComplaint: {
        paddingLeft: 25
    },
    title: {
        fontSize: 23,
        lineHeight: '30px',
        fontWeight: 400,
        letterSpacing: '-1px',
        color: theme.palette.typography && theme.palette.typography.variant1,
        marginBottom: 5
    },
    policy: {
        fontSize: 23,
        lineHeight: '30px',
        fontWeight: 400,
        letterSpacing: '-1px',
        color: theme.palette.primary && theme.palette.primary.main,
        marginBottom: 14,
        cursor: 'pointer'
    },
    status: {
        marginTop: 10,
        fontSize: 13,
        lineHeight: '24px',
        fontWeight: 300,
        color: theme.palette.typography && theme.palette.typography.main
    }
}));
