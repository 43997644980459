import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import rootReducer from "../reducers";

const initialState = {};

const middleware = [thunk];

const persistConfig = {
  key: "root_cotizador_backoffice",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(...middleware, promise)
  )
);

const persistor = persistStore(store);

export { store, persistor };
