import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Grid,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import "swiper/swiper.min.css";
// import "swiper/css/scrollbar";
import { Close } from "@material-ui/icons";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import {
  blockFilter,
  selectServiceTypeNetwork,
  selectSupplierTypeNetwork,
} from "../../actions/medicalNetworkActions";
import { MEDICAL_NETWORK_QUERY } from "../../utils/urls";
import { VariableSizeList } from "react-window";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: "none",
    position: "absolute",
    width: "96%",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#fff",
    zIndex: 4,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)",
    borderRadius: 4,
  },
  show: {
    display: "block",
  },
  desktopFilterContainer: {
    position: "relative",
    width: "100%",
    display: "block",
  },
  content: {
    position: "relative",
    padding: "1rem",
  },
  title: {
    margin: "10px 0",
    color: "#4B4B4B",
    fontSize: 16,
    letterSpacing: 0.15,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 12,
    width: 20,
    height: 20,
  },
  active: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    color: "#fff !important",
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },
  },
  button: {
    color: theme.palette.secondary.light,
    backgroundColor: "#fff",
    border: `1px solid ${theme.palette.secondary.light}`,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  buttonsGroup: {
    padding: "1rem 0",
    "& .MuiGrid-item": {
      textAlign: "center",
      flex: "25%",
    },
  },
  buttonLabel: {
    fontSize: 10,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    color: "#979797",
    display: "block",
    marginTop: 5,
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: "1rem 0",
  },
  chip: {
    color: theme.palette.secondary.light,
    letterSpacing: 0.25,
    fontSize: 12,
    borderColor: theme.palette.secondary.light,
    margin: 5,
  },
  formControl: {
    width: "100%",
    margin: "1rem 0",
  },
  filterButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem 0",
    borderTop: "0.25px solid rgba(151, 151, 151, 0.3)",
  },
  filterButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  scrollbar: {
    overflow: "visible !important",
    "& .swiper-slide": {
      width: "auto !important",
    },
    "& .swiper-scrollbar": {
      display: "none",
    },
  },
}));

// For long autocomplete with react-window
const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 50;

  const getChildSize = (child) => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const MedicalNetworkFilter = ({
  element,
  open,
  offset,
  handleClose,
  handleAddFilter,
  filters,
  handleReload,
  onFilterClean,
  desktop,
  handleSupplier,
  setChips,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const medicalNetworkTypes = useSelector(
    (selector) => selector.medicalNetworkTypes
  );
  const medicalNetworkServices = useSelector(
    (selector) => selector.medicalNetworkServices.data
  );
  const [show, setShow] = useState(false);
  const [filterLocation, setFilterLocation] = useState({
    ...filters.location,
    type: "location",
  });
  let top = 0;
  if (element) {
    const rect = element.getBoundingClientRect();
    top = rect.top + window.scrollY;
  }

  useEffect(() => {
    !isEmpty(filters.location.value) && setShow(true);
  }, [filters]);

  const filterTypeLocation = {
    street: "Calle",
    city: "Ciudad",
    state: "Estado",
  };
  return (
    <div>
      <div
        className={clsx(
          classes.filterContainer,
          open && classes.show,
          desktop && classes.desktopFilterContainer
        )}
        style={{ top: desktop ? 0 : top + offset }}
      >
        <div className={classes.content}>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              onFilterClean();
              handleClose(null);
              dispatch(selectServiceTypeNetwork(""));
              dispatch(selectSupplierTypeNetwork(""));
            }}
          >
            <Close />
          </IconButton>

          <Typography
            component="span"
            variant="body1"
            className={classes.title}
          >
            Tipos de red proveedor
          </Typography>
          <Grid container style={{ marginBottom: 10, marginTop: 10 }}>
            <Autocomplete
              fullWidth
              ListboxComponent={ListboxComponent}
              onChange={(e, value) => {
                if (!value) {
                  dispatch(selectSupplierTypeNetwork(""));
                  onFilterClean({
                    type: "types",
                    value: [],
                  });
                  setChips((prevChips) =>
                    prevChips.filter((chip) => chip.type !== "types")
                  );
                  return;
                } else {
                  handleSupplier(value, !true);
                  if (MEDICAL_NETWORK_QUERY === "sise") {
                    dispatch(selectSupplierTypeNetwork(value));
                  }
                  handleAddFilter({
                    type: "types",
                    value: value,
                  });
                  setChips((prevChips) => {
                    const newchip = {
                      type: "types",
                      value: value,
                    };
                    if (prevChips && prevChips.length) {
                      const all = prevChips.filter(
                        (item) => item.type !== newchip.type
                      );
                      return [...all, newchip];
                    }
                    return [newchip];
                  });
                }
              }}
              value={
                (medicalNetworkTypes && medicalNetworkTypes.supplier) || ""
              }
              disableListWrap
              disableCloseOnSelect
              openOnFocus
              classes={classes}
              options={
                medicalNetworkTypes &&
                medicalNetworkTypes.data &&
                medicalNetworkTypes.data.map(({ name }) => name)
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
          <Typography
            component="span"
            variant="body1"
            className={classes.title}
          >
            Especialidad
          </Typography>
          <Autocomplete
            ListboxComponent={ListboxComponent}
            onChange={(e, value) => {
              if (!value) {
                dispatch(selectServiceTypeNetwork(""));
                onFilterClean({
                  type: "specialities",
                  value: [],
                });
                setChips((prevChips) =>
                  prevChips.filter((chip) => chip.type !== "specialities")
                );
                return;
              }
              if (MEDICAL_NETWORK_QUERY === "sise") {
                dispatch(selectServiceTypeNetwork(value));
              }
              handleAddFilter({
                type: "specialities",
                value: value,
              });
              setChips((prevChips) => {
                const newchip = {
                  type: "specialities",
                  value: value,
                };
                if (prevChips && prevChips.length) {
                  const all = prevChips.filter(
                    (item) => item.type !== newchip.type
                  );
                  return [...all, newchip];
                }
                return [newchip];
              });
            }}
            value={(medicalNetworkTypes && medicalNetworkTypes.service) || ""}
            disableListWrap
            disableCloseOnSelect
            openOnFocus
            classes={classes}
            options={medicalNetworkServices}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />

          <Typography
            component="span"
            variant="body1"
            className={classes.title}
          >
            Ubicación
          </Typography>
          <Grid container>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="location-select-label">Seleccionar</InputLabel>
              <Select
                onChange={(e) => {
                  const val = e.target.value;
                  const obj = {
                    ...filterLocation,
                    type: "location",
                    value: val,
                    search: "",
                  };
                  handleAddFilter(obj);
                  setFilterLocation(obj);
                  setShow(val ? true : false);
                }}
                label="Seleccionar"
                labelId="location-select-label"
                id="location-select"
                value={filters.location.value}
              >
                <MenuItem value={null}>Seleccionar</MenuItem>
                <MenuItem value="street">Calle</MenuItem>
                <MenuItem value="city">Ciudad</MenuItem>
                <MenuItem value="state">Estado</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {show && !isEmpty(filters.location.value) && (
            <Grid container>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="outlined-textbox"
                  label="Nombre"
                  defaultValue={filters.location.search}
                  value={filterLocation.search}
                  placeholder="Ingrese un nombre"
                  variant="outlined"
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleReload();
                      handleClose(null);
                    }
                  }}
                  onChange={(e) => {
                    const val = e.target.value;
                    const obj = { ...filterLocation, search: val };
                    handleAddFilter(obj);
                    setFilterLocation(obj);

                    setChips((prevChips) => {
                      const newchip = {
                        type: "location",
                        value: `${filterTypeLocation[obj.value]}=${obj.search}`,
                      };

                      if (prevChips && prevChips.length) {
                        const all = prevChips.filter(
                          (item) => item.type !== newchip.type
                        );
                        return [...all, newchip];
                      }
                      return [newchip];
                    });
                    dispatch(blockFilter(true));
                  }}
                />
              </FormControl>
            </Grid>
          )}
        </div>
        <div className={classes.filterButtonContainer}>
          <Button
            variant="contained"
            className={classes.filterButton}
            onClick={() => {
              handleReload();
              handleClose(null);
            }}
          >
            Aplicar filtros
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MedicalNetworkFilter;
