import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    widthDrawer: {
        '& .MuiPaper-root': {
            width: '80%'
        }
    },
    bannerHeader: {
        position: 'relative',
        '& .iConnectance-logo': {
            width: 100,
            position: 'absolute',
            top: 10,
            left: 10
        }
    },
    logout: {
        position: 'absolute !important',
        top: 0,
        right: 5,
        zIndex: 3
    },
    profile: {
        padding: '0px 20px',
        top: 0,
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center'
    },
    iconUser: {
        backgroundColor: theme.palette.primary.main
    },
    userName: {
        fontSize: 15,
        fontWeight: 700
    },
    rol: {
        paddingLeft: 5,
        fontSize: 12
    },

    menuOptions: {
        paddingLeft: 15,
        paddingTop: 10
    },
    activeLink: {
        "& .MuiSvgIcon-root": {
            color:  theme.palette.primary.main,
        },
        "& .MuiListItem-root": {
            color: theme.palette.primary.main,
        },
        '& .MuiListItemText-primary': {
            fontWeight: 700,
            color: theme.palette.primary.main,
        },
        "& .hover-effect": {
            width: "100% !important",
            height: "100%",
            left: 0,
            top: 0,
            position: "absolute",
            backgroundColor: "#F4F4FA",
            borderTopLeftRadius: 40,
            borderBottomLeftRadius: 40,
            zIndex: -1,
            transition: "width 0.5s ease-in",
        },
        "& .list-option": {
            '& .MuiListItemIcon-root': {
                minWidth: 0
            }
        }
    },
    disabledActiveLink: {
        "& .list-option": {
            '& .MuiListItemIcon-root': {
                minWidth: 0
            }
        }
    },
    icon: {
        color: theme.palette.primary.main,
        minWidth: 0
    },
    iconActive: {
        "& svg": {
          color: theme.palette.secondary.main,
        },
      },
    titleMenu: {
        paddingLeft: 10
    },
    optionOpen: {
        backgroundColor: '#F4F4FA',
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30
    },
    listItem: {
        borderTopLeftRadius: 40,
        borderBottomLeftRadius: 40,
        paddingTop: 0,
        '& .MuiListItemText-root': {
            paddingLeft: 10
        },
        '& .MuiListItemIcon-root': {
            minWidth: 0
        },
        '&.MuiListItem-button:hover': {
            backgroundColor: '#F4F4FA'
        }
    },
    listItemCollapsibleItem: {
        '& .MuiListItem-root': {
            paddingLeft: 10,
            color: theme.palette.secondary.main
        }
    }
}))