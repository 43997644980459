import React from "react";
import { Virtuoso as List } from 'react-virtuoso'
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import NetworkCard from "../medicalNetworkCard/NetworkCard";

const useStyles = makeStyles({
  container: {
    position: "relative",
  },
});

const ListContainer = (props) => {
  const classes = useStyles();
  return <Container maxWidth="sm" className={classes.container} {...props} />;
};

const VirtualizedListNetwork = (props) => {
  const [data, setData] = React.useState([]);

  if (data.length === 0) {
    setData(Array.from({ length: 500 }).map((_) => null));
  }

  const isItemLoaded = (index) => index < data.length && data[index] !== null;
  const loadMoreItems = (startIndex, stopIndex) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const newData = [...data];
        for (let idx = startIndex; idx < stopIndex; idx++) {
          newData[idx] = props.items[idx];
        }
        setData(newData);
        resolve();
      }, 2000);
    });
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={props?.items?.length}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <List
              style={{ height, width }}
              totalCount={props?.items?.length}
              itemContent={(index) => <NetworkCard index={index} data={{items: data, ...props}}/> }
              onItemsRendered={onItemsRendered}
              ref={ref}
            />
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default VirtualizedListNetwork;
