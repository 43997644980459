import React, { useState, useEffect } from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Avatar,
    Typography,
    Button,
    Fab,
    CircularProgress
} from '@material-ui/core';

import TextInput from '../../../../inputValidator/TextInput';
import PhoneInput from '../../../../inputValidator/PhoneInput';
import EmailInput from '../../../../inputValidator/EmailInput';
import IconPencil from '../../../../../assets/images/iconPencilWhite.svg';
import { updateUserInfo } from '../../../../../actions/userInfoActions';
import { getDataToken } from '../../../../../utils/index';
import Utils from '../../../../../utils/utils';
import { useStyles } from './styles';

import { INSURED_CLIENT } from '../../../../../utils/urls';
import AvatarDefault from '../../../../../assets/images/avatar_libra_asegurados.png';

const EditProfileBasic = ({
    dataProfile,
    disableInput,
    getDataProfile,
    handleDisableEditProfile
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const isVisible = INSURED_CLIENT !== 'LIBRA';

    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState({
        _id: '',
        userId: '',
        fullName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        pictureUrl: ''
    });
    const [imageErrorText, setImageErrorText] = useState(null);
    const [dataToken, setDataToken] = useState();

    const handleSubmit = async () => {
        setLoading(true);
        await dispatch(updateUserInfo(dataForm));
        handleDisableEditProfile();
        await getDataProfile();
        setLoading(false);
    };

    const handleChange = (e) => {
        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        });
    };

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const imageDelete = () => {
        setDataForm({
            ...dataForm,
            pictureUrl: null
        });
    };

    const handlePreviewImage = async (e) => {
        const file = e.target.files[0];
        const imgSize = Math.round(file.size / 1024);

        if (imgSize >= 80) {
            setImageErrorText('La imagen de perfil no debe ser mayor a 80kb');
            setTimeout(() => {
                setImageErrorText(null);
            }, 3000);
            return e.preventDefault();
        }

        const validateType = Utils.validateFile(file);
        if (validateType) {
            setImageErrorText(validateType);
            setTimeout(() => {
                setImageErrorText(null);
            }, 3000);
            return e.preventDefault();
        }

        try {
            const imageToForm = await toBase64(file);
            setDataForm({
                ...dataForm,
                pictureUrl: imageToForm
            });

            setImageErrorText(null);
        } catch (error) {
            imageDelete();
            setImageErrorText('Ocurrio un error, intente de nuevo');
        }
    };

    useEffect(() => {
        const dataToken = getDataToken();

        if (dataProfile) {
            setDataForm({
                ...dataForm,
                _id: dataProfile._id,
                userId: dataProfile.userId.id,
                fullName: dataProfile.fullName,
                firstName: dataProfile.firstName,
                lastName: dataProfile.lastName,
                email: dataToken.user.email,
                address: dataProfile.address ? dataProfile.address : '',
                phone: dataProfile.phone ? dataProfile.phone : '',
                pictureUrl: dataProfile.pictureUrl
                    ? dataProfile.pictureUrl
                    : '',
                cc: dataProfile.cc ? dataProfile.cc : ''
            });
            setDataToken(dataToken);
        }
    }, [dataProfile]);

    const renderAvatar = () => {
        return (
            <>
                {dataForm.pictureUrl ? (
                    <Avatar
                        src={dataForm.pictureUrl}
                        className={classes.photoProfile}
                    />
                ) : (
                    <Avatar className={classes.photoProfile}>
                        <Typography
                            component="h4"
                            style={{ fontStyle: 'uppercase' }}
                        >
                            {`${dataForm.firstName.charAt(
                                0
                            )}${dataForm.lastName.charAt(0)}`}
                        </Typography>
                    </Avatar>
                )}
            </>
        );
    };

    const renderAvatarLibra = () => {
        return (
            <>
                {dataForm.pictureUrl ? (
                    <Avatar
                        src={dataForm.pictureUrl}
                        className={classes.photoProfile}
                    />
                ) : (
                    <Avatar
                        src={AvatarDefault}
                        className={classes.photoProfile}
                    />
                )}
            </>
        );
    };

    return (
        <ValidatorForm instantValidate={true} onSubmit={() => handleSubmit()}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.sectionAvatar}
            >
                <Grid style={{ position: 'relative' }}>
                    {INSURED_CLIENT === 'LIBRA'
                        ? renderAvatarLibra()
                        : renderAvatar()}

                    <label
                        htmlFor="upload-photo"
                        style={{
                            position: 'absolute',
                            bottom: '0px',
                            right: '0px'
                        }}
                    >
                        <input
                            style={{ display: 'none' }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            disabled={disableInput}
                            onChange={(e) => handlePreviewImage(e)}
                        />
                        {!disableInput && (
                            <Fab component="span" className="iconUpload">
                                <img src={IconPencil} />
                            </Fab>
                        )}
                    </label>
                </Grid>
                <Typography className={classes.imgMessageError}>
                    {imageErrorText}
                </Typography>
                <Typography
                    variant="h6"
                    className="nameProfile"
                >{`${dataForm.firstName} ${dataForm.lastName}`}</Typography>
                <Typography variant="subtitle2" className="codeBroker">
                    Codigo de asegurado:{' '}
                    {dataToken && dataToken.user.insuredCode}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.spaceInput}>
                <TextInput
                    label="NOMBRE"
                    onChange={(e) => handleChange(e)}
                    name="firstName"
                    value={dataForm.firstName}
                    validators={[
                        'required',
                        'minStringLength:1',
                        'maxStringLength:40'
                    ]}
                    errorMessages={[
                        'El campo es requerido',
                        'Debe ingresar mas carácteres',
                        'Número máximo de caracteres'
                    ]}
                    edit={disableInput}
                />
            </Grid>
            <Grid item xs={12} className={classes.spaceInput}>
                <TextInput
                    label="APELLIDO"
                    onChange={(e) => handleChange(e)}
                    name="lastName"
                    value={dataForm.lastName}
                    validators={[
                        'required',
                        'minStringLength:1',
                        'maxStringLength:40'
                    ]}
                    errorMessages={[
                        'El campo es requerido',
                        'Debe ingresar mas carácteres',
                        'Número máximo de caracteres'
                    ]}
                    edit={disableInput}
                />
            </Grid>
            <Grid item xs={12} className={classes.spaceInput}>
                <EmailInput
                    label="CORREO ELECTRONICO"
                    onChange={(e) => handleChange(e)}
                    name="email"
                    value={dataForm.email}
                    validators={['required', 'isEmail']}
                    errorMessages={[
                        'El campo es requerido',
                        'Email no es válido'
                    ]}
                    edit={true}
                />
            </Grid>
            {isVisible && (
                <Grid item xs={12} className={classes.spaceInput}>
                    <TextInput
                        label="DIRECCION"
                        onChange={(e) => handleChange(e)}
                        name="address"
                        value={dataForm.address}
                        validators={[
                            'required',
                            'minStringLength:1',
                            'maxStringLength:40'
                        ]}
                        errorMessages={[
                            'El campo es requerido',
                            'Debe ingresar mas carácteres',
                            'Número máximo de caracteres'
                        ]}
                        edit={disableInput}
                    />
                </Grid>
            )}

            <Grid item xs={12} className={classes.spaceInput}>
                <PhoneInput
                    label="TELÉFONO"
                    name="phone"
                    value={dataForm.phone}
                    cc={dataForm.cc}
                    validators={[
                        'required',
                        'minStringLength:8',
                        'maxStringLength:12'
                    ]}
                    errorMessages={[
                        'El campo es requerido',
                        'La cantidad minima de campos es 8',
                        'Número máximo de caracteres es de 12'
                    ]}
                    edit={disableInput}
                    onInputChange={(e) => handleChange(e)}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={!disableInput ? classes.sectBtnSave : null}
            >
                <div className={classes.wrapper}>
                    <Button
                        disabled={disableInput || loading}
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.btn}
                    >
                        Guardar Básico
                    </Button>
                    {loading && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    )}
                </div>
            </Grid>
        </ValidatorForm>
    );
};

export default EditProfileBasic;
