import {
  getMedicalNetworkAPI,
  getMedicalNetworkPointAttentionTypeAPI,
  getMedicalNetworkServicesAPI,
  getMedicalNetworkSISEAPI,
  getServicesSISEAPI,
  getSuppliersAPI,
} from "./../sevices/medicalNetworkService";
import {
  SELECT_MEDICAL_SERVICE,
  CLEAR_MEDICAL_NETWORK,
  SEARCH_MEDICAL_NETWORK,
  SELECT_SUPPLIER,
  SELECT_SERVICE,
  BLOCK_NETWORK_FILTER,
} from "./types";

export const getMedicalNetwork =
  (token, page, search, filtersList, isSISE = false, suppliers = [], service) =>
  async (dispatch) => {
    if (isSISE) {
      return await dispatch(
        getMedicalNetworkSISEAPI(token, search, filtersList, suppliers, service)
      );
    }
    // dont use suppliers here
    return await dispatch(
      getMedicalNetworkAPI(token, page, search, filtersList)
    );
  };

export const clearMedicalNetwork = () => (dispatch) => {
  dispatch({ type: CLEAR_MEDICAL_NETWORK });
};

export const selectMedicalService = (medicalService) => (dispatch) => {
  dispatch({ type: SELECT_MEDICAL_SERVICE, payload: medicalService });
};

export const getMedicalNetworkServices =
  (token, isSISE = false) =>
  async (dispatch) => {
    if (isSISE) {
      return await dispatch(getServicesSISEAPI(token));
    }
    return await dispatch(getMedicalNetworkServicesAPI(token));
  };

export const getMedicalNetworkTypes =
  (token, isSISE = false) =>
  async (dispatch) => {
    if (isSISE) {
      return await dispatch(getSuppliersAPI(token));
    }
    return await dispatch(getMedicalNetworkPointAttentionTypeAPI(token));
  };

export const searchMedicalNetwork = (search) => async (dispatch) => {
  await dispatch({ type: SEARCH_MEDICAL_NETWORK, payload: search });
};

export const selectSupplierTypeNetwork = (name) => async (dispatch) => {
  await dispatch({ type: SELECT_SUPPLIER, payload: { name } });
};

export const selectServiceTypeNetwork = (name) => async (dispatch) => {
  await dispatch({ type: SELECT_SERVICE, payload: name });
};

export const blockFilter =
  (isBlock = false) =>
  (dispatch) => {
    dispatch({ type: BLOCK_NETWORK_FILTER, payload: isBlock });
  };
