import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import VirtualizedListNetwork from "./VirtualizedListNetwork";

const MedicalNetworkCardContainer = ({
  data,
  loading,
  handleOptionsToggle,
  handleSelect,
  isMobile = false,
  onChangeIndex,
}) => {
  const rdc = data.filter(
    (v, i, a) => a.findIndex((t) => t._id === v._id) === i
  );

  return (
    <>
      <VirtualizedListNetwork
        items={rdc}
        handleOptionsToggle={handleOptionsToggle}
        handleSelect={handleSelect}
        onChangeIndex={onChangeIndex}
      />
      {!isMobile ? (
        <Grid container justifyContent="center">
        {loading && <CircularProgress />}
      </Grid>
      ) : null}
    </>
  );
};

export default MedicalNetworkCardContainer;
