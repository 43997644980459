import React, { useEffect, useState } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { Button, Grid } from '@material-ui/core';
import {
    renderTextField,
    renderDateField,
    renderNumberField
} from '../form/renderInputsForm';
import { renderPhoneField } from '../inputValidator/renderInputsForm';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';

const validate = (values) => {
    const errors = {};
    const requiredFields = [
        'name',
        'lastname',
        'phoneNumber',
        'typeLicense',
        'license',
        'endLicense'
    ];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    return errors;
};

function VehicleDriverForm({ handleSubmit, handleStep }) {
    const [endDate, setEndDate] = useState();
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        const today = moment().format('MM/DD/YYYY');
        setEndDate(today);
        dispatch(change('vehicledriver', 'endLicense', today));
    }, []);

    const handleSubmits = (e) => {
        handleStep({
            step: 'driver'
        });
    };

    return (
        <form onSubmit={handleSubmit(handleSubmits)}>
            <Grid container direction="row">
                <Grid item xs={8} md={4} style={{ padding: 8 }}>
                    <Field
                        name="name"
                        label="Nombre"
                        component={renderTextField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                <Grid item xs={8} md={4} style={{ padding: 8 }}>
                    <Field
                        name="lastname"
                        label="Apellido"
                        component={renderTextField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                <Grid item xs={8} md={4} style={{ padding: 8 }}>
                    <Field
                        name="phoneNumber"
                        label="Telefono"
                        component={renderPhoneField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>

                <Grid item xs={8} md={4} style={{ padding: 8 }}>
                    <Field
                        name="typeLicense"
                        label="Categoria de licencia"
                        component={renderTextField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>

                <Grid item xs={8} md={4} style={{ padding: 8 }}>
                    <Field
                        name="license"
                        label="Nro de licencia"
                        component={renderTextField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        maxLength="8"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        normalize={(val) => (val || '').replace(/[^\d]/g, '')}
                    />
                </Grid>
                <Grid item xs={8} md={4} style={{ padding: 8 }}>
                    <Field
                        name="endLicense"
                        label="Vencimiento de licencia"
                        component={renderDateField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        dateValue={endDate}
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                    />
                </Grid>

                <Grid item xs={12} md={12} style={{ padding: 0 }}>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            className={classes.buttonNextStep}
                        >
                            Siguiente
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

VehicleDriverForm.propTypes = {};

export default reduxForm({ form: 'vehicledriver', validate })(
    VehicleDriverForm
);
