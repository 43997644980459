import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm, change, reset } from 'redux-form';
import PropTypes from 'prop-types';
import {
    Button,
    Grid,
    IconButton,
    Drawer,
    TextField,
    MenuItem,
    Typography
} from '@material-ui/core';
import { renderTextField, renderSelectField } from '../form/renderInputsForm';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { getDocumentsList } from '../../actions/listDocumentsAction.js';
import { validate } from '../../utils/validateInputSinisterPolicy';
import InputList from '../InputList';

const useStylesForm = makeStyles((theme) => ({
    sectFormComment: {
        display: 'flex',
        alignItems: 'baseline',
        padding: '15px !important'
    },
    fontSizeLabel: {
        '& label': {
            fontSize: 14
        }
    }
}));

function ThirdDataForm({ handleStep, valid }) {
    const dispatch = useDispatch();
    const initialState = {
        clientName: '',
        email: '',
        phone: '',
        document: '',
        documentType: '',
        domain: '',
        location: '',
        company: '',
        brand: '',
        model: '',
        patente: '',
        observations: ''
    };
    const [drawerState, setDrawerState] = useState(false);
    const classes = useStyles();
    const [saveThirdData, setSaveThirdData] = useState([]);
    const [formThirdData, setFormThirdData] = useState(initialState);
    const { listDocumentsType } = useSelector((state) => state);

    useEffect(() => {
        dispatch(getDocumentsList());
    }, []);

    const formatListDocument = useMemo(() => {
        return listDocumentsType.data?.result?.map((x) => x);
    }, [listDocumentsType]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormThirdData({
            ...formThirdData,
            [name]: value
        });
    };

    const fieldThirdData = useMemo(() => {
        return [
            {
                validations: { pattern: '', message: '' },
                value: null,
                remote: null,
                dependency: [],
                isOptional: true,
                mask: 'none',
                name: 'clientName',
                label: 'Nombre y apellido',
                dataType: 'string',
                controlType: 'input text',
                order: 0
            },
            {
                validations: { pattern: '', message: '' },
                value: null,
                remote: null,
                dependency: [],
                isOptional: false,
                mask: 'none',
                name: 'email',
                label: 'Email',
                dataType: 'string',
                controlType: 'input text',
                order: 1
            },
            {
                validations: { pattern: '', message: '' },
                value: null,
                remote: null,
                dependency: [],
                isOptional: false,
                mask: 'none',
                name: 'phone',
                label: 'Telefono',
                dataType: 'string',
                controlType: 'input text',
                order: 2
            }
        ];
    });

    const loadThirdData = () => {
        setDrawerState(true);
    };

    const toggleDrawer = (open) => {
        setDrawerState(open);
    };

    const handleNext = () => {
        handleStep({
            step: 'thirdData'
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSaveThirdData([...saveThirdData, formThirdData]);
        dispatch(reset('thirdData'));
        setFormThirdData(initialState);
        toggleDrawer(false);
    };

    return (
        <Grid container direction="row">
            <Grid item xs={12} md={12} style={{ padding: 8 }}>
                <IconButton onClick={loadThirdData}>
                    Agregar datos <PersonAddIcon />
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <InputList
                    items={saveThirdData}
                    fields={fieldThirdData}
                    label="Datos de Terceros"
                    setItems={setSaveThirdData}
                    add={false}
                />
            </Grid>
            <Drawer
                anchor="right"
                open={drawerState}
                onClose={() => toggleDrawer(false)}
                classes={{
                    paper: classes.drawerEl
                }}
            >
                <header className={classes.headerDrawer}>
                    <div className={classes.titleContainer}>
                        <span>Cargar Datos de Terceros</span>
                    </div>
                    <IconButton
                        aria-label="close details"
                        onClick={() => toggleDrawer(false)}
                    >
                        <Close style={{ color: '#fff' }} />
                    </IconButton>
                </header>
                <form name="thirdData" onSubmit={handleSubmit}>
                    <Grid container direction="row">
                        <Grid item xs={6} md={4} style={{ padding: 10 }}>
                            <Field
                                name="clientName"
                                label="Nombre y Apellido"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.clientName}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} style={{ padding: 10 }}>
                            <Field
                                name="email"
                                label="Email"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.email}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} style={{ padding: 10 }}>
                            <Field
                                name="phone"
                                label="Telefono"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.phone}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4} md={4} style={{ padding: 10 }}>
                            <Field
                                name="documentType"
                                label="Tipo de document"
                                component={renderSelectField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.documentType}
                                onChange={handleInputChange}
                            >
                                {formatListDocument &&
                                    formatListDocument.map((doc) => (
                                        <MenuItem
                                            key={doc.cod_tipo_doc}
                                            value={doc.txt_desc_redu}
                                        >
                                            {doc.txt_desc}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={8} md={8} style={{ padding: 10 }}>
                            <Field
                                name="document"
                                label="Número de documento"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.document}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4} md={4} style={{ padding: 10 }}>
                            <Field
                                name="domain"
                                label="Dominio"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.domain}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4} md={4} style={{ padding: 10 }}>
                            <Field
                                name="location"
                                label="Domicilio"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.location}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={4} md={4} style={{ padding: 10 }}>
                            <Field
                                name="company"
                                label="Compañia de seguros"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.company}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} style={{ padding: 10 }}>
                            <Field
                                name="brand"
                                label="Marca"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.brand}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} style={{ padding: 10 }}>
                            <Field
                                name="model"
                                label="Modelo"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.model}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} style={{ padding: 10 }}>
                            <Field
                                name="patente"
                                label="Patente"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.patente}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item sx={12} md={12} style={{ padding: 10 }}>
                            <TextField
                                id="outlined-multiline-static"
                                name="observations"
                                label="Observaciones"
                                multiline
                                rows={4}
                                defaultValue={formThirdData.observations}
                                fullWidth
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div className={classes.buttonContainer}>
                                <Button
                                    type="submit"
                                    className={classes.buttonNextStep}
                                    disabled={!valid}
                                >
                                    Guardar
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Drawer>

            <Grid item xs={12} md={12} style={{ padding: 0 }}>
                <div className={classes.buttonContainer}>
                    <Button
                        onClick={handleNext}
                        className={classes.buttonNextStep}
                    >
                        Siguiente
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

ThirdDataForm.propTypes = {};

export default reduxForm({ form: 'thirdData', validate })(ThirdDataForm);
