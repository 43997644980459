import React, { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import { useDispatch, useSelector } from 'react-redux'
import {
    Grid,
    Button
} from '@material-ui/core';

import { setPasswordUser } from '../../../../../actions/userInfoActions'
import TextInput from '../../../../inputValidator/TextInput';
import { useStyles } from './styles';
import MessageError from '../../../../../components/messages/messageError';
import MessageSuccess from '../../../../../components/messages/messageSuccess';

const EditPassword = ({ disableInput, handleDisableEditProfile }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const [dataForm, setDataForm] = useState({
        oldPassword: '',
        newPassword: '',
        matchPassword: ''
    });
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = () => {
        dispatch(setPasswordUser(token, {
            oldPassword: dataForm.oldPassword,
            password: dataForm.newPassword
        }));

        setDisabled(true);
        handleDisableEditProfile();
    }

    useEffect(() => {
        if (disableInput) {
            setDisabled(false);
        }
    }, [disableInput])

    const handleChange = (e) => {
        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        })
    }

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== dataForm.newPassword) {
            return false;
        }
        return true;
    });

    return (
        <ValidatorForm
            instantValidate={true}
            onSubmit={() => handleSubmit()}
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.sectionPassword}
            >
                <Grid item xs={12} className={classes.spaceInput}>
                    <TextInput
                        label="CONTRASEÑA ACTUAL"
                        onChange={(e) => handleChange(e)}
                        type="password"
                        name="oldPassword"
                        value={dataForm.oldPassword}
                        validators={["required"]}
                        errorMessages={["El campo es requerido"]}
                        edit={disableInput}
                    />
                </Grid>
                <Grid item xs={12} className={classes.spaceInput}>
                    <TextInput
                        label="NUEVA CONTRASEÑA"
                        onChange={(e) => handleChange(e)}
                        type="password"
                        name="newPassword"
                        value={dataForm.newPassword}
                        validators={["required", "minStringLength:8", "matchRegexp:^(?=.*[A-Z]{1})(?=.*[!@#$%^&*()_+])(?=.*[0-9a-z]{1,10}).{8,12}$"]}
                        errorMessages={["El campo es requerido", "Debe tener al menos 8 caracteres" ,"Debe tener una letra en mayuscula, un numero y un caracter especial."]}
                        edit={disableInput}
                    />
                </Grid>
                <Grid item xs={12} className={classes.spaceInput}>
                    <TextInput
                        label="CONFIRMAR CONTRASEÑA"
                        onChange={(e) => handleChange(e)}
                        type="password"
                        name="matchPassword"
                        value={dataForm.matchPassword}
                        validators={["required", "isPasswordMatch"]}
                        errorMessages={["El campo es requerido", "La contraseña no coincide"]}
                        edit={disableInput}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.sectBtnSave}
                >
                    <Button disabled={disableInput} variant="contained" color="primary" type="submit">
                        Guardar Cambios
                    </Button>
                </Grid>
            </Grid>
            <MessageError />
            <MessageSuccess />

        </ValidatorForm>
    );
}

export default EditPassword;