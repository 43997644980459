import React from 'react';
import {
    Grid,
    SvgIcon,
    Typography,
    Card,
    LinearProgress
} from '@material-ui/core';
import moment from 'moment';
import { cardComplaintsStyle } from './style';
import IconSinister from '../../../Icon/Sinister';
import { withStyles } from '@material-ui/styles';
import { INSURED_CLIENT } from '../../../../utils/urls';

const CardComplaints = ({ sinister, total, position, handleOpenDrawer }) => {
    const index = position && position;
    const classes = cardComplaintsStyle();
    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            borderRadius: 5
        },
        colorPrimary: {
            backgroundColor: '#C8B4F3'
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#6716F5'
        }
    }))(LinearProgress);

    return (
        <Card className={classes.root}>
            <div className={classes.pagination}>
                {index}/{total}
            </div>
            <Grid container alignItems="center" className={classes.cardStatus}>
                <Grid item container xs={3} justifyContent="center">
                    <IconSinister className={classes.icon} />
                </Grid>
                <Grid item xs={8} className={classes.dataComplaint}>
                    <Typography className={classes.title}>
                        {sinister?.estado || `Sin Estado`}
                    </Typography>
                    <Typography
                        className={classes.policy}
                        onClick={handleOpenDrawer}
                    >
                        #{sinister && sinister.nro_siniestro}
                    </Typography>
                    <Typography className={classes.status}>
                        {`Fecha de ocurrencia: ${moment(
                            sinister?.fecha_incurrido
                        ).format('DD/MM/YYYY')}`}
                    </Typography>
                    <Typography className={classes.status}>
                        {`Fecha de denuncia: ${moment(
                            sinister?.fecha_registro
                        ).format('DD/MM/YYYY')}`}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};

export default CardComplaints;
