import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useStyles } from './styles';
import { CircularProgress, Typography } from '@material-ui/core';

import { ButtonEdit } from './ExtraActionsSinister';
import DetailPolicyForm from './DetailPolicyForm';
import DetailGeneralForm from './DetailsGeneralForm.js';
import VehicleDriverForm from './VehicleDriverForm';
import ClaimTypeForm from './ClaimTypeForm';
import DetailsSupportForm from './DetailsSupportForm';
import ThirdDataForm from './ThirdDataForm';

const steps = [
    {
        name: 'Detalles del Siniestro',
        component: 'general'
    },
    {
        name: 'Lugar y Forma de Ocurrencia',
        component: 'policy'
    },
    {
        name: 'Soportes',
        component: 'support'
    }
];

const stepsAuto = [
    {
        name: 'Detalles del Siniestro',
        component: 'general'
    },
    {
        name: 'Lugar y Forma de Ocurrencia',
        component: 'policy'
    },
    {
        name: 'Conductor del Vehiculo Asegurado',
        component: 'driver'
    },
    {
        name: 'Detalle del reclamo',
        component: 'claim'
    },
    {
        name: 'Datos de terceros',
        component: 'thirdData'
    },
    {
        name: 'Soportes',
        component: 'support'
    }
];

function AdvanceLineSinister() {
    const [actualStep, setActualStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [stepsData, setStepsData] = useState();
    const [policy, setPolicy] = useState(null);
    const [coberturas, setCoberturas] = useState([]);
    const classes = useStyles();
    const refTitle = useRef();

    useEffect(() => {
        setStepsData(steps);
    }, []);

    useEffect(() => {
        if (refTitle.current) {
            refTitle.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [actualStep]);

    useEffect(() => {
        console.log('policy', policy);
        if (policy?.ramo === 'AUTO') {
            setStepsData(stepsAuto);
        } else {
            setStepsData(steps);
        }
    }, [policy]);

    const handleClickStep = () => {
        setActualStep((actualStep) =>
            actualStep < stepsData.length ? actualStep + 1 : actualStep
        );
    };

    const handleClickEdit = (editStep) => {
        setActualStep(editStep);
    };

    const components = {
        policy: (
            <DetailGeneralForm
                handleStep={handleClickStep}
                coberturas={coberturas}
            />
        ),
        general: (
            <DetailPolicyForm
                handleStep={handleClickStep}
                setPolicy={setPolicy}
                setCoberturas={setCoberturas}
            />
        ),
        driver: <VehicleDriverForm handleStep={handleClickStep} />,
        claim: <ClaimTypeForm handleStep={handleClickStep} />,
        thirdData: <ThirdDataForm handleStep={handleClickStep} />,
        support: <DetailsSupportForm handleStep={handleClickStep} />
    };

    return (
        <>
            <ul className={classes.outline}>
                {stepsData?.slice(0, actualStep).map((step, i) => (
                    <li key={i} className={classes.liContainer} ref={refTitle}>
                        <div className={classes.circle} color={'#F4F4FA'}>
                            {i + 1}
                        </div>
                        <div className={classes.titleStep}>
                            {step.name}
                            <ButtonEdit
                                onClick={() => {
                                    handleClickEdit(i + 1);
                                }}
                            />
                        </div>
                        {i < stepsData?.length - 1 && (
                            <hr className={classes.line} />
                        )}
                        <div
                            className={
                                i + 1 != actualStep || loading
                                    ? classes.stepActionsContainerDisabled
                                    : classes.stepActionsContainer
                            }
                        >
                            {components[step.component]}
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default AdvanceLineSinister;
