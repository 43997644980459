import React, { useState } from 'react';
import { Link } from '@reach/router';
import {
    Hidden,
    BottomNavigation,
    BottomNavigationAction,
    ListItem
} from '@material-ui/core';
import {
    Dashboard,
    Notifications,
    LocalHospital,
    Help,
    ChromeReaderMode
} from '@material-ui/icons';
import { useStyles } from './styles';

const NavLinkItem = ({ children, ...props }) => (
    <Link
        {...props}
        getProps={({ isCurrent }) => {
            return {
                className: isCurrent
                    ? props.className + ' Mui-selected'
                    : props.className
            };
        }}
    >
        <ListItem>
            <div className="hover-effect" style={{ width: 0 }} />
            {children}
        </ListItem>
    </Link>
);

const MenuBottom = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Hidden smUp>
            <BottomNavigation
                value={value}
                onChange={handleChange}
                className={classes.menuBottom}
            >
                <BottomNavigationAction
                    showLabel={false}
                    className={classes.navActions}
                    value="dashboard"
                    icon={<Dashboard />}
                    component={NavLinkItem}
                    to="dashboard"
                />
                <BottomNavigationAction
                    showLabel={false}
                    className={classes.navActions}
                    value="medical-network"
                    icon={<LocalHospital />}
                    component={NavLinkItem}
                    to="medical-network"
                />
                <BottomNavigationAction
                    showLabel={false}
                    className={classes.navActions}
                    value="notifications"
                    icon={<Notifications />}
                    component={NavLinkItem}
                    to="notifications"
                />
                <BottomNavigationAction
                    showLabel={false}
                    className={classes.navActions}
                    value="news"
                    icon={<ChromeReaderMode />}
                    component={NavLinkItem}
                    to="news"
                />
                {/*  <BottomNavigationAction
          showLabel={false}
          className={classes.navActions}
          value="faq"
          icon={<Help />}
          component={NavLinkItem}
          to="faq"
        /> */}
            </BottomNavigation>
        </Hidden>
    );
};

export default MenuBottom;
