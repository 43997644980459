import React, { useState } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';
import { Button, Grid, TextField } from '@material-ui/core';
import {
    renderTextField,
    renderSelectField,
    renderDateField,
    renderRadioGroup
} from '../form/renderInputsForm';
import { RenderSearchSelectFieldSimple } from '../quoter/quoteInputs';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const validate = (values) => {
    const errors = {};
    const requiredFields = ['descriptionClaim'];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    return errors;
};

function ClaimTypeForm({ handleSubmit, handleStep }) {
    const classes = useStyles();

    //const dispatch = useDispatch();

    const handleSubmits = (e) => {
        handleStep({
            step: 'claim'
        });
    };

    return (
        <form onSubmit={handleSubmit(handleSubmits)}>
            <Grid container direction="row">
                <Grid item xs={6} md={4} style={{ padding: 8 }}>
                    <Field
                        name="colissionType"
                        label="Tipo de colisión"
                        component={renderSelectField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{ padding: 8 }}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Descripcion detallada del reclamo"
                        name="descriptionClaim"
                        multiline
                        rows={4}
                        defaultValue=""
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={12} style={{ padding: 0 }}>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            className={classes.buttonNextStep}
                        >
                            Siguiente
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

ClaimTypeForm.propTypes = {};

export default reduxForm({ form: 'claimtype', validate })(ClaimTypeForm);
