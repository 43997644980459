import { makeStyles } from '@material-ui/core';

export const cardComplaintsStyle = makeStyles((theme) => ({
    root: {
        height: '100%',
        position: 'relative',
        padding: 25,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        },
        borderRight: `5px solid ${
            theme.palette.variants && theme.palette.variants.variant1
        }`,
        boxShadow: `3px 0px 1px ${
            theme.palette.variants && theme.palette.variants.variant3
        }`,
        borderBottomLeftRadius: 18,
        borderTopLeftRadius: 18
    },
    icon: {
        width: 70,
        height: 70
    },
    cardStatus: {
        marginTop: 20
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    status: {
        color: ({ colorStatus }) => colorStatus,
        display: 'flex',
        '& > svg': {
            fill: 'transparent'
        },
        '& > p': {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: '1.25px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
           marginBottom: 10
        },
    },
    info: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    dueDate: {
        color: theme.palette.neutral.grey3,
        fontSize: 12,
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            whiteSpace: "nowrap"
        },
        fontWeight: 400,
        lineHeight: '14.06px',
        letterSpacing: '-0.04px',
        marginRight: 10
    },
    stateColor: {
        color: ({ colorStatus }) => colorStatus
    },
    pagination: {
        fontSize: 12,
        color: theme.palette.neutral.grey3
    },
    dataComplaint: {
        paddingLeft: 25
    },
    policy: {
        fontSize: 23,
        lineHeight: '30px',
        fontWeight: 400,
        letterSpacing: '-1px',
        color: '#333',
        marginBottom: 5
    },
    payment: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '-1px',
        color: '#333',
        marginBottom: 10
    },
    present: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '-1px',
        color: '#333',
        marginBottom: 10,
        marginRight: 50
    },
    sinister: {
        fontSize: 23,
        lineHeight: '30px',
        fontWeight: 400,
        letterSpacing: '1px',
        color: theme.palette.primary.dark,
        marginBottom: 14,
        cursor: 'pointer'
    },
    dateRegister: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.neutral.grey4
    }
}));
