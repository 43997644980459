import { makeStyles } from '@material-ui/core';
import familyWide from './../../assets/images/family-wide.png';
import familySmall from './../../assets/images/family-small.png';
import familyMedium from './../../assets/images/family-medium.png';
import banner from './../../assets/images/banner.svg';
import libraBanner from './../../assets/images/libraBanner.png';
import { INSURED_CLIENT } from '../../utils/urls';

export const useStyles = makeStyles((theme) => ({
    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" },
    infoBannerDefault: {
        backgroundImage: `url(${
            INSURED_CLIENT === 'LIBRA' ? libraBanner : banner
        })`,
        maskImage: `url(${INSURED_CLIENT === 'LIBRA' ? libraBanner : banner})`,
        height: 279,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        [theme.breakpoints.down('xs')]: {
            height: 239
        }
    },
    infoBanner: {
        overflow: 'hidden',
        backgroundImage: `url(${familyWide})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: 279,
        width: '100vw',
        paddingBottom: 80,
        paddingLeft: 113,
        [theme.breakpoints.down('md')]: {
            backgroundImage: `url(${familyMedium})`
        },
        [theme.breakpoints.down('xs')]: {
            backgroundImage: `url(${familySmall})`,
            paddingLeft: 25,
            paddingBottom: 65,
            height: 239
        }
    },
    banner: {
        overflow: 'hidden',
        backgroundColor: '#E0E0E0',
        maxHeight: 279,
        marginBottom: 60,
        [theme.breakpoints.down('xs')]: {
            maxHeight: 239,
            marginBottom: 44
        },
        '& .swiper-pagination-bullet:not(.swiper-pagination-bullet-active)': {
            opacity: 1,
            background: '#FFF'
        }
    },
    rootSwiper: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        '& .swiper-left-arrow, .swiper-right-arrow': {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            position: 'absolute',
            top: 87,
            zIndex: 100,
            '& > svg': {
                width: 40,
                height: 40
            }
        },
        '& .swiper-left-arrow': {
            left: -40,
            [theme.breakpoints.down('xs')]: {
                left: -35
            },
            '&.swiper-button-disabled': {
                display: 'none'
            }
        },
        '& .swiper-right-arrow': {
            right: -40,
            [theme.breakpoints.down('xs')]: {
                right: -35
            },
            '&.swiper-button-disabled': {
                display: 'none'
            }
        }
    },
    sliderInsured: {
        marginLeft: 0,
        '& .swiper-slide': {
            minWidth: 255,
            paddingTop: 10,
            paddingBottom: 10,
            height: `auto !important`
        }
    },
    swiperPay: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        '& .swiper-container': {
            marginLeft: 0
        },
        '& .swiper-slide-active > div': {
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        '& .pay-left-arrow, .pay-right-arrow': {
            position: 'absolute',
            top: 84,
            zIndex: 100,
            '& > svg': {
                width: 40,
                height: 40
            }
        },
        '& .pay-left-arrow': {
            left: -30,
            '&.swiper-button-disabled': {
                display: 'none'
            }
        },
        '& .pay-right-arrow': {
            right: -30,
            '&.swiper-button-disabled': {
                display: 'none'
            }
        }
    },
    root: {
        marginTop: 6,
        [theme.breakpoints.down('xs')]: {
            marginTop: 0
        }
    },
    content: {
        height: '100%',
        paddingLeft: 85,
        paddingRight: 100,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 25,
            paddingRight: 25,
            paddingBottom: 65
        }
    },
    subtitle: {
        fontWeight: 500,
        fontSize: 24,
        fontFamily: 'Work Sans',
        lineHeight: '36px',
        letterSpacing: '-1px',
        color: '#333',
        marginBottom: 15
    },
    toolTip: {
        backgroundColor: theme.palette.primary?.main,
        padding: '15px 15px'
    },
    arrow: {
        color: theme.palette.primary?.main
    },
    carousel: {
        width: '100%',
        overflow: 'visible'
    },
    prev: {
        left: '-50px',
        [theme.breakpoints.down('xs')]: {
            left: '-40px'
        }
    },
    next: {
        right: '-50px',
        [theme.breakpoints.down('xs')]: {
            right: '-40px'
        }
    },
    indicators: {
        display: 'none'
    },
    filterMenu: {
        width: 450,
        borderTopLeftRadius: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    closeBtn: {
        position: 'absolute',
        top: 14,
        right: 10
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '3rem'
        }
    },
    sectLeftDashboard: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10
    },
    sectRenovate: {
        padding: '0px 20px 20px 0px',
        [theme.breakpoints.down('xs')]: {
            padding: '20px 0px'
        }
    },
    sectCardDetail: {
        paddingTop: 20,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        '& > div': {
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            },
            display: 'flex',
            '& .CarouselItem': {
                '& > div': {
                    height: '100%'
                },
                [theme.breakpoints.down('xs')]: {
                    width: '100%'
                }
            }
        }
    },
    cardDetail: {
        padding: '10px 10px'
    },
    btnAction: {
        padding: '20px 10px 0px'
    },
    sectReferCard: {
        padding: '20px 10px 0px',
        '& .message': {
            width: '70%',
            zIndex: 2,
            [theme.breakpoints.down('md')]: {
                width: '100%',
                zIndex: 2
            }
        }
    },
    titleCard1: {
        fontSize: 23
    },
    user: {
        fontSize: 23
    },
    sectButton: {
        paddingTop: 20,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    button: {
        backgroundColor:
            theme.palette.variants && theme.palette.variants.variant3,
        color: 'white'
    },
    img: {
        position: 'absolute',
        right: 0,
        top: '40%',
        transform: 'translate(-16%, -26%)',
        bottom: 0,
        width: '34%'
    },
    imgRefer: {
        maxWidth: 'auto',
        height: 202,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    //Cards
    sectCard: {
        padding: '20px 10px 0px'
    },
    paddingCard: {
        padding: 20
    },
    btnCard: {
        width: '100%',
        height: 40,
        [theme.breakpoints.down('xs')]: {
            fontSize: 10
        }
    },
    btnCardBlue: {
        width: '100%',
        backgroundColor:
            theme.palette.secondary && theme.palette.secondary.light,
        color: 'white',
        height: 40,
        marginTop: 15,
        [theme.breakpoints.down('sm')]: {
            bottom: 0,
            position: 'relative'
        }
    },
    titleCard2: {
        fontSize: 16,
        color: theme.palette.typography && theme.palette.typography.main,
        [theme.breakpoints.down('xs')]: {
            fontSize: 12
        }
    },
    amount: {
        fontSize: 40,
        fontWeight: 900,
        padding: '15px 0px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        }
    },
    sectCarouselComplaint: {
        height: '100%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            marginTop: 20
        }
    },
    carouselComplaint: {
        height: '100%',
        '& > .CarouselItem > div, & > .CarouselItem': { height: '100%' }
    },
    iconSinister: {
        width: 25,
        height: 25
    }
}));
