import React, { useEffect, useState, useMemo } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, MenuItem } from '@material-ui/core';
import {
    renderTextField,
    renderSelectField,
    renderDateField,
    renderTimeField
} from '../form/renderInputsForm';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { getQsiseDashboard } from '../../actions/qSiseDashboardAction';
import { getItemsPolicy } from '../../actions/itemsPolicyAction';
import { getAllRiskType } from '../../actions/riskTypeActions';
import { getClaimCauses } from '../../actions/claimCausesAction';

const validate = (values) => {
    const errors = {};
    const requiredFields = [
        'startDate',
        'timeSinister',
        'policy',
        'tomador',
        'endDate',
        'items'
    ];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    return errors;
};

function DetailPolicyForm({
    handleSubmit,
    handleStep,
    setPolicy,
    setCoberturas
}) {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState();
    const [time, setTime] = useState();
    const [endDate, setEndDate] = useState();
    const [policyData, setPolicyData] = useState([]);
    const classes = useStyles();
    const { auth, risktype } = useSelector((state) => state);

    const { token } = auth;

    const { ...qSiseDashboard } = useSelector((state) => state.qSiseDashboard);
    const {
        data: { data: itemSinister }
    } = useSelector((state) => state.items);

    const executeItemsPolicy = async (id_pv) => {
        await dispatch(getItemsPolicy(token, id_pv));
    };

    const executeClaimCauses = async (prefixCode) => {
        await dispatch(getClaimCauses(token, prefixCode));
    };

    // ** call execute query policies
    useEffect(() => {
        if (Object.keys(qSiseDashboard.data).length === 0) {
            dispatch(getQsiseDashboard(token));
        }
        if (Object.keys(risktype.data).length === 0) {
            dispatch(getAllRiskType(token));
        }
        /* if (Object.keys(insurance.data).length === 0) {
            dispatch(getInsurance(token));
        } */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (qSiseDashboard?.data?.data) {
            setPolicyData(qSiseDashboard?.data?.data?.listaPolizas?.result);
        }
    }, [qSiseDashboard]);

    useEffect(() => {
        const today = moment().format('MM/DD/YYYY');
        const hour = moment().format();
        setStartDate(today);
        dispatch(change('detailpolicy', 'startDate', today));
        setTime(hour);
        dispatch(
            change('detailpolicy', 'timeSinister', moment().format('HH:mm A'))
        );
    }, []);

    const handleSubmits = (values) => {
        console.log('values', values);
        handleStep({
            step: 'general'
        });
    };

    const fetchItems = async (e) => {
        const id_pv = e.target.value;
        const policy = policyData.find((policy) => policy.id_pv === id_pv);
        setPolicy(policy);
        const end = moment(policy.fecha_fin).format('MM/DD/YYYY');
        setEndDate(end);
        dispatch(change('detailpolicy', 'endDate', end));
        dispatch(
            change(
                'detailpolicy',
                'tomador',
                policy?.tomador || 'Nombre del Tomador'
            )
        );
        await executeItemsPolicy(id_pv);
        await executeClaimCauses(policy?.codigo_ramo || 0);
    };

    const handleItemSelected = async (e) => {
        const item = e.target.value;
        await setCoberturas(item?.coberturas ? item.coberturas.split(',') : []);
    };

    return (
        <form onSubmit={handleSubmit(handleSubmits)}>
            <Grid container direction="row">
                <Grid item xs={8} md={4} style={{ padding: 8 }}>
                    <Field
                        minDate={moment().format('MM/DD/YYYY')}
                        maxDate={moment(new Date()).add(30, 'days')}
                        name="startDate"
                        label="Fecha de ocurrencia"
                        value={startDate}
                        dateValue={startDate}
                        onChange={(date) => setStartDate(date)}
                        component={renderDateField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                <Grid item xs={8} md={4} style={{ padding: 8 }}>
                    <Field
                        name="timeSinister"
                        label="Hora de ocurrencia"
                        component={renderTimeField}
                        custom={{ tabIndex: 0 }}
                        value={time}
                        dateValue={time}
                        onChange={(date) => setTime(date)}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                <Grid item xs={8} md={8} style={{ padding: 8 }}>
                    <Field
                        name="policy"
                        label="Seleccionar Póliza"
                        component={renderSelectField}
                        custom={{ tabIndex: 6 }}
                        onChange={fetchItems}
                    >
                        {policyData &&
                            policyData.map((item) => (
                                <MenuItem key={item.id_pv} value={item.id_pv}>
                                    {`Póliza nro: ${item.nro_poliza} - ${item.descripcion_item} - Ramo: ${item.ramo}`}
                                </MenuItem>
                            ))}
                    </Field>
                </Grid>

                <Grid item xs={8} md={4} style={{ padding: 8 }}>
                    <Field
                        name="tomador"
                        label="Nombre del tomador"
                        component={renderTextField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        disabled
                    />
                </Grid>
                <Grid item xs={4} md={4} style={{ padding: 8 }}>
                    <Field
                        name="endDate"
                        label="Vigencia"
                        value={endDate}
                        dateValue={endDate}
                        component={renderDateField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={8} style={{ padding: 8 }}>
                    <Field
                        name="items"
                        label="Item a siniestrar"
                        component={renderSelectField}
                        custom={{ tabIndex: 6 }}
                        onChange={handleItemSelected}
                    >
                        {itemSinister?.length > 0 &&
                            itemSinister?.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item.descripcion_item}
                                </MenuItem>
                            ))}
                    </Field>
                </Grid>

                <Grid item xs={12} md={12} style={{ padding: 0 }}>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            className={classes.buttonNextStep}
                        >
                            Siguiente
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

DetailPolicyForm.propTypes = {};

export default reduxForm({ form: 'detailpolicy', validate })(DetailPolicyForm);
