import jwt_decode from 'jwt-decode';

export const getParams = function (url) {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }

    return params;
};

export const getDataToken = (token = null) => {
    if (token) {
        const tokenDecode = jwt_decode(token);
        return tokenDecode;
    } else {
        const obj = JSON.parse(
            localStorage.getItem('persist:root_cotizador_backoffice')
        );
        const auth = JSON.parse(obj.auth);
        const tokenDecode = jwt_decode(auth.token);

        return tokenDecode;
    }
};

export const getToken = () => {
    const obj = JSON.parse(
        localStorage.getItem('persist:root_cotizador_backoffice')
    );
    const auth = JSON.parse(obj.auth);

    return auth.token;
};

export const headers = () => {
    const obj = JSON.parse(
        localStorage.getItem('persist:root_cotizador_backoffice')
    );
    const auth = JSON.parse(obj.auth);
    const headers = { Authorization: `Bearer ${auth.token}` };

    return { headers };
};

export const b64toBlob = (
    b64Data,
    fileName,
    contentType = '',
    sliceSize = 512
) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
};

export const truncateText = (text, defaultLength = 45) => {
    if (text && text.length > defaultLength) {
        text = `${text.substring(0, defaultLength)}...`;
    }
    return text;
};
