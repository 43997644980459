import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import {
    Avatar,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { postLogin, patchErrorLogin, setAuthToken } from '../actions/authActions';
import { useInputValue } from '../hooks/useInputValue';
import LoadingModal from '../components/modal/loadingModal';
import MessageFailure from '../components/messages/messageFailure';
import { authUrlBase } from '../utils/urls';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.common.white,
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

const Login = ({ location }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth);
    const user = useInputValue('');
    const password = useInputValue('');

    const token = new URLSearchParams(location.search).get('token');

    if (!isAuth.token && token) {
        dispatch(setAuthToken(token));
    }
    useEffect(() => {
        if (isAuth.token) {
            navigate('/dashboard');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth.token]);

    const handleSubmitUser = (event) => {
        event.preventDefault();
        if (user.value && password.value) {
            const credential = { email: user.value, password: password.value };
            dispatch(postLogin(credential));
        }
    };

    const handleErrorLogin = () => {
        dispatch(patchErrorLogin());
    };

    if (!token) {
        return window.location.assign(`${authUrlBase}login/insured?redirect=${window.location.origin}`);
    }

    return (
        <Grid container justifyContent="center" className="appContainer">
            {isAuth.loading && <LoadingModal />}
            {isAuth.error && <MessageFailure close={handleErrorLogin} />}
            <Grid
                item
                xs={12}
                md={4}
                className="cardAppContainer"
                style={{ marginTop: 32 }}
            >
                <Grid
                    container
                    justifyContent="center"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
						Login
                    </Typography>
                </Grid>
                <form
                    className={classes.form}
                    onSubmit={(event) => handleSubmitUser(event)}
                >
                    <TextField
                        label="Usuario"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoFocus
                        {...user}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        label="contraseña"
                        type="password"
                        required
                        fullWidth
                        {...password}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Recordar Usuario"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
						Ingresar
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
								Olvido su contraseña?
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default Login;
