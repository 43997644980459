import { makeStyles } from "@material-ui/core";

export const cardPayStyle = makeStyles((theme) => ({
  content: {
    paddingLeft: 40,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 10,
  },
  root: {
    cursor: "pointer",
    width: "100%",
    height: ({ client }) => (client === "LIBRA" ? "auto" : "100%"),
    borderRadius: 18,
    boxShadow: "none",
  },
  header: {
    marginBottom: 12,
  },
  title: {
    color: "#66788A",
    fontSize: 22,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "-1px",
    marginBottom: 10,
  },
  status: {
    color: ({ colorStatus }) => colorStatus,
    display: "flex",
    "& > svg": {
      fill: "currentColor",
    },
    "& > p": {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "16px",
      letterSpacing: "1.25px",
    },
  },
  link: {
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "14.06px",
    letterSpacing: "-0.04px",
    display: "flex",
  },
  info: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  description: {
    display: "block",
    color: theme.palette.neutral.grey5,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "-0.04px",
  },
  iconbutton: {
    backgroundColor: theme.palette.neutral.grey2,
    fontSize: 12,
    width: 15,
    height: 15,
  },
  button: {
    boxShadow: "none",
    width: 146,
    color: "#fff",
    backgroundColor: ({ colorStatus }) => colorStatus,
    [theme.breakpoints.down("xs")]: {
      width: "40%",
    },
  },
  actions: {
    marginTop: 20,
  },
  price: {
    display: "block",
    fontSize: 36,
    [theme.breakpoints.down("xs")]: {
      fontSize: 28,
    },
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.06px",
  },
  month: {
    color: "#C4C4C4",
    textTransform: "uppercase",
    fontSize: 36,
    [theme.breakpoints.down("xs")]: {
      fontSize: 28,
    },
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.06px",
  },
  dueDate: {
    color: theme.palette.neutral.grey3,
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
    fontWeight: 400,
    lineHeight: "14.06px",
    letterSpacing: "-0.04px",
    marginRight: 7,
  },
  stateColor: {
    color: ({ colorStatus }) => colorStatus,
  },
}));
