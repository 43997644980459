export const GET_MEDICAL_NETWORK_SISE = 'GET_MEDICAL_NETWORK_SISE';
export const GET_SUPPLIER_MEDICAL_NETWORK_SISE = 'GET_SUPPLIER_MEDICAL_NETWORK_SISE';
export const SELECT_SUPPLIER = 'SELECT_SUPPLIER';
export const SELECT_SERVICE = 'SELECT_SERVICE';
export const BLOCK_NETWORK_FILTER = 'BLOCK_NETWORK_FILTER';
export const GET_MEDICAL_NETWORK_PENDING = 'GET_MEDICAL_NETWORK_PENDING';
export const GET_MEDICAL_NETWORK_FULFILLED = 'GET_MEDICAL_NETWORK_FULFILLED';
export const GET_MEDICAL_NETWORK_REJECTED = 'GET_MEDICAL_NETWORK_REJECTED';
export const CLEAR_MEDICAL_NETWORK = 'CLEAR_MEDICAL_NETWORK';
export const SEARCH_MEDICAL_NETWORK = 'SEARCH_MEDICAL_NETWORK';

export const GET_Q_SISE_ITEMSPOLICY_REJECTED =
    'GET_Q_SISE_ITEMSPOLICY_REJECTED';
export const GET_Q_SISE_ITEMSPOLICY_PENDING = 'GET_Q_SISE_ITEMSPOLICY_PENDING';
export const GET_Q_SISE_ITEMSPOLICY = 'GET_Q_SISE_ITEMSPOLICY';
export const GET_Q_SISE_ITEMSPOLICY_FULFILLED =
    'GET_Q_SISE_ITEMSPOLICY_FULFILLED';

export const SELECT_MEDICAL_SERVICE = 'SELECT_MEDICAL_SERVICE';

export const POST_LOGIN_PENDING = 'POST_LOGIN_PENDING';
export const POST_LOGIN_FULFILLED = 'POST_LOGIN_FULFILLED';
export const POST_LOGIN_REJECTED = 'POST_LOGIN_REJECTED';
export const PATCH_LOGIN_ERROR = 'PATCH_LOGIN_ERROR';

export const GET_LOGOUT_PENDING = 'GET_LOGOUT_PENDING';
export const GET_LOGOUT_FULFILLED = 'GET_LOGOUT_FULFILLED';
export const GET_LOGOUT_REJECTED = 'GET_LOGOUT_REJECTED';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

export const GET_CHECK_AUTH_PENDING = 'GET_CHECK_AUTH_PENDING';
export const GET_CHECK_AUTH_FULFILLED = 'GET_CHECK_AUTH_FULFILLED';
export const GET_CHECK_AUTH_REJECTED = 'GET_CHECK_AUTH_REJECTED';

export const GET_PRODUCT_PENDING = 'GET_PRODUCT_PENDING';
export const GET_PRODUCT_FULFILLED = 'GET_PRODUCT_FULFILLED';
export const GET_PRODUCT_REJECTED = 'GET_PRODUCT_REJECTED';

export const ADD_PRODUCT_PENDING = 'ADD_PRODUCT_PENDING';
export const ADD_PRODUCT_FULFILLED = 'ADD_PRODUCT_FULFILLED';
export const ADD_PRODUCT_REJECTED = 'ADD_PRODUCT_REJECTED';

export const UPDATE_PRODUCT_PENDING = 'UPDATE_PRODUCT_PENDING';
export const UPDATE_PRODUCT_FULFILLED = 'UPDATE_PRODUCT_FULFILLED';
export const UPDATE_PRODUCT_REJECTED = 'UPDATE_PRODUCT_REJECTED';

export const DELETE_PRODUCT_PENDING = 'DELETE_PRODUCT_PENDING';
export const DELETE_PRODUCT_FULFILLED = 'DELETE_PRODUCT_FULFILLED';
export const DELETE_PRODUCT_REJECTED = 'DELETE_PRODUCT_REJECTED';

export const GET_LINE_BUSSINESS_PENDING = 'GET_LINE_BUSSINESS_PENDING';
export const GET_LINE_BUSSINESS_FULFILLED = 'GET_LINE_BUSSINESS_FULFILLED';
export const GET_LINE_BUSSINESS_REJECTED = 'GET_LINE_BUSSINESS_REJECTED';

export const GET_CURRENCY_PENDING = 'GET_CURRENCY_PENDING';
export const GET_CURRENCY_FULFILLED = 'GET_CURRENCY_FULFILLED';
export const GET_CURRENCY_REJECTED = 'GET_CURRENCY_REJECTED';

export const GET_RISK_TYPE_PENDING = 'GET_RISK_TYPE_PENDING';
export const GET_RISK_TYPE_FULFILLED = 'GET_RISK_TYPE_FULFILLED';
export const GET_RISK_TYPE_REJECTED = 'GET_RISK_TYPE_REJECTED';

export const GET_COVER_PENDING = 'GET_COVER_PENDING';
export const GET_COVER_FULFILLED = 'GET_COVER_FULFILLED';
export const GET_COVER_REJECTED = 'GET_COVER_REJECTED';

export const GET_COVER_PRODUCT = 'GET_COVER_PRODUCT';
export const ADD_COVER_PRODUCT = 'ADD_COVER_PRODUCT';
export const UPDATE_COVER_PRODUCT = 'UPDATE_COVER_PRODUCT';
export const DELETE_COVER_PRODUCT = 'DELETE_COVER_PRODUCT';

export const GET_INSURANCE_PRODUCT = 'GET_INSURANCE_PRODUCT';
export const ADD_INSURANCE_PRODUCT = 'ADD_INSURANCE_PRODUCT';
export const DELETE_INSURANCE_PRODUCT = 'DELETE_INSURANCE_PRODUCT';
export const CLEAN_INSURANCE_PRODUCT = 'CLEAN_INSURANCE_PRODUCT';

export const GET_ASISTANCE_PENDING = 'GET_ASISTANCE_PENDING';
export const GET_ASISTANCE_FULFILLED = 'GET_ASISTANCE_FULFILLED';
export const GET_ASISTANCE_REJECTED = 'GET_ASISTANCE_REJECTED';

export const GET_ASISTANCE_PRODUCT = 'GET_ASISTANCE_PRODUCT';
export const ADD_ASISTANCE_PRODUCT = 'ADD_ASISTANCE_PRODUCT';
export const UPDATE_ASISTANCE_PRODUCT = 'UPDATE_ASISTANCE_PRODUCT';
export const DELETE_ASISTANCE_PRODUCT = 'DELETE_ASISTANCE_PRODUCT';

export const GET_TAX_PENDING = 'GET_TAX_PENDING';
export const GET_TAX_FULFILLED = 'GET_TAX_FULFILLED';
export const GET_TAX_REJECTED = 'GET_TAX_REJECTED';

export const GET_TAX_PRODUCT = 'GET_TAX_PRODUCT';
export const ADD_TAX_PRODUCT = 'ADD_TAX_PRODUCT';
export const DELETE_TAX_PRODUCT = 'DELETE_TAX_PRODUCT';

export const GET_TARIFF_PENDING = 'GET_TARIFF_PENDING';
export const GET_TARIFF_FULFILLED = 'GET_TARIFF_FULFILLED';
export const GET_TARIFF_REJECTED = 'GET_TARIFF_REJECTED';

export const GET_TARIFF_PRODUCT = 'GET_TARIFF_PRODUCT';
export const ADD_TARIFF_PRODUCT = 'ADD_TARIFF_PRODUCT';
export const DELETE_TARIFF_PRODUCT = 'DELETE_TARIFF_PRODUCT';

export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';

export const GET_LEADS_PENDING = 'GET_LEADS_PENDING';
export const GET_LEADS_FULFILLED = 'GET_LEADS_FULFILLED';
export const GET_LEADS_REJECTED = 'GET_LEADS_REJECTED';

export const FILTER_LEADS = 'FILTER_LEADS';
export const RESET_LEADS = 'RESET_LEADS';
export const SORT_LEADS = 'SORT_LEADS';

export const CHECK_TRAFFICS_DATA = 'CHECK_TRAFFICS_DATA';

export const GET_INSURANCE_PENDING = 'GET_INSURANCE_PENDING';
export const GET_INSURANCE_FULFILLED = 'GET_INSURANCE_FULFILLED';
export const GET_INSURANCE_REJECTED = 'GET_INSURANCE_REJECTED';

export const ADD_INSURANCE_PENDING = 'ADD_INSURANCE_PENDING';
export const ADD_INSURANCE_FULFILLED = 'ADD_INSURANCE_FULFILLED';
export const ADD_INSURANCE_REJECTED = 'ADD_INSURANCE_REJECTED';

export const UPDATE_INSURANCE_PENDING = 'UPDATE_INSURANCE_PENDING';
export const UPDATE_INSURANCE_FULFILLED = 'UPDATE_INSURANCE_FULFILLED';
export const UPDATE_INSURANCE_REJECTED = 'UPDATE_INSURANCE_REJECTED';

export const DELETE_INSURANCE_PENDING = 'DELETE_INSURANCE_PENDING';
export const DELETE_INSURANCE_FULFILLED = 'DELETE_INSURANCE_FULFILLED';
export const DELETE_INSURANCE_REJECTED = 'DELETE_INSURANCE_REJECTED';

export const FILTER_INSURANCES = 'FILTER_INSURANCES';
export const RESET_INSURANCES = 'RESET_INSURANCES';
export const GET_QUOTATION_SCREEN_PENDING = 'GET_QUOTATION_SCREEN_PENDING';
export const GET_QUOTATION_SCREEN_FULFILLED = 'GET_QUOTATION_SCREEN_FULFILLED';
export const GET_QUOTATION_SCREEN_REJECTED = 'GET_QUOTATION_SCREEN_REJECTED';

export const GET_QUOTATION_SCREEN_BY_ID_PENDING =
    'GET_QUOTATION_SCREEN_BY_ID_PENDING';
export const GET_QUOTATION_SCREEN_BY_ID_FULFILLED =
    'GET_QUOTATION_SCREEN_BY_ID_FULFILLED';
export const GET_QUOTATION_SCREEN_BY_ID_REJECTED =
    'GET_QUOTATION_SCREEN_BY_ID_REJECTED';

export const ADD_QUOTATION_SCREEN_PENDING = 'ADD_QUOTATION_SCREEN_PENDING';
export const ADD_QUOTATION_SCREEN_FULFILLED = 'ADD_QUOTATION_SCREEN_FULFILLED';
export const ADD_QUOTATION_SCREEN_REJECTED = 'ADD_QUOTATION_SCREEN_REJECTED';

export const UPDATE_QUOTATION_SCREEN_PENDING =
    'UPDATE_QUOTATION_SCREEN_PENDING';
export const UPDATE_QUOTATION_SCREEN_FULFILLED =
    'UPDATE_QUOTATION_SCREEN_FULFILLED';
export const UPDATE_QUOTATION_SCREEN_REJECTED =
    'UPDATE_QUOTATION_SCREEN_REJECTED';

export const DELETE_QUOTATION_SCREEN_PENDING =
    'DELETE_QUOTATION_SCREEN_PENDING';
export const DELETE_QUOTATION_SCREEN_FULFILLED =
    'DELETE_QUOTATION_SCREEN_FULFILLED';
export const DELETE_QUOTATION_SCREEN_REJECTED =
    'DELETE_QUOTATION_SCREEN_REJECTED';

export const UPDATE_QUOTATION_SYNC = 'UPDATE_QUOTATION_SYNC';

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_PENDING = 'GET_DASHBOARD_DATA_PENDING';
export const GET_DASHBOARD_DATA_FULFILLED = 'GET_DASHBOARD_DATA_FULFILLED';
export const GET_DASHBOARD_DATA_REJECTED = 'GET_DASHBOARD_DATA_REJECTED';
export const RESET_DASHBOARD = 'RESET_DASHBOARD';
export const FILTER_DASHBOARD = 'FILTER_DASHBOARD';

export const ADD_QUOTATION_SCREEN_GROUP = 'ADD_QUOTATION_SCREEN_GROUP';
export const UPDATE_QUOTATION_SCREEN_GROUP = 'UPDATE_QUOTATION_SCREEN_GROUP';
export const DELETE_QUOTATION_SCREEN_GROUP = 'DELETE_QUOTATION_SCREEN_GROUP';

export const ADD_QUOTATION_SCREEN_GROUP_FIELD =
    'ADD_QUOTATION_SCREEN_GROUP_FIELD';
export const UPDATE_QUOTATION_SCREEN_GROUP_FIELD =
    'UPDATE_QUOTATION_SCREEN_GROUP_FIELD';
export const DELETE_QUOTATION_SCREEN_GROUP_FIELD =
    'DELETE_QUOTATION_SCREEN_GROUP_FIELD';

export const SWAP_QUOTATION_SCREEN_GROUP_FIELDS =
    'SWAP_QUOTATION_SCREEN_GROUP_FIELDS';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_PENDING = 'GET_USERS_PENDING';
export const GET_USERS_FULFILLED = 'GET_USERS_FULFILLED';
export const GET_USERS_REJECTED = 'GET_USERS_REJECTED';

export const ADD_USER_PENDING = 'ADD_USER_PENDING';
export const ADD_USER_FULFILLED = 'ADD_USER_FULFILLED';
export const ADD_USER_REJECTED = 'ADD_USER_REJECTED';

export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED';
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED';

export const DELETE_USER_PENDING = 'DELETE_USER_PENDING';
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED';
export const DELETE_USER_REJECTED = 'DELETE_USER_REJECTED';

export const FILTER_USERS = 'FILTER_USERS';
export const RESET_USERS = 'RESET_USERS';

export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_PENDING = 'GET_ROLES_PENDING';
export const GET_ROLES_FULFILLED = 'GET_ROLES_FULFILLED';
export const GET_ROLES_REJECTED = 'GET_ROLES_REJECTED';

export const ADD_ROLE_PENDING = 'ADD_ROLE_PENDING';
export const ADD_ROLE_FULFILLED = 'ADD_ROLE_FULFILLED';
export const ADD_ROLE_REJECTED = 'ADD_ROLE_REJECTED';

export const UPDATE_ROLE_PENDING = 'UPDATE_ROLE_PENDING';
export const UPDATE_ROLE_FULFILLED = 'UPDATE_ROLE_FULFILLED';
export const UPDATE_ROLE_REJECTED = 'UPDATE_ROLE_REJECTED';

export const DELETE_ROLE_PENDING = 'DELETE_ROLE_PENDING';
export const DELETE_ROLE_FULFILLED = 'DELETE_ROLE_FULFILLED';
export const DELETE_ROLE_REJECTED = 'DELETE_ROLE_REJECTED';

export const FILTER_ROLES = 'FILTER_ROLES';
export const RESET_ROLES = 'RESET_ROLES';

export const GET_RESOURCES = 'GET_RESOURCES';
export const GET_RESOURCES_PENDING = 'GET_RESOURCES_PENDING';
export const GET_RESOURCES_FULFILLED = 'GET_RESOURCES_FULFILLED';
export const GET_RESOURCES_REJECTED = 'GET_RESOURCES_REJECTED';

export const ADD_RESOURCE = 'ADD_RESOURCE';
export const ADD_RESOURCE_PENDING = 'ADD_RESOURCE_PENDING';
export const ADD_RESOURCE_FULFILLED = 'ADD_RESOURCE_FULFILLED';
export const ADD_RESOURCE_REJECTED = 'ADD_RESOURCE_REJECTED';

export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const UPDATE_RESOURCE_PENDING = 'UPDATE_RESOURCE_PENDING';
export const UPDATE_RESOURCE_FULFILLED = 'UPDATE_RESOURCE_FULFILLED';
export const UPDATE_RESOURCE_REJECTED = 'UPDATE_RESOURCE_REJECTED';

export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const DELETE_RESOURCE_PENDING = 'DELETE_RESOURCE_PENDING';
export const DELETE_RESOURCE_FULFILLED = 'DELETE_RESOURCE_FULFILLED';
export const DELETE_RESOURCE_REJECTED = 'DELETE_RESOURCE_REJECTED';

export const GET_REGISTRATIONS = 'GET_REGISTRATIONS';
export const GET_REGISTRATIONS_PENDING = 'GET_REGISTRATIONS_PENDING';
export const GET_REGISTRATIONS_FULFILLED = 'GET_REGISTRATIONS_FULFILLED';
export const GET_REGISTRATIONS_REJECTED = 'GET_REGISTRATIONS_REJECTED';

export const DELETE_REGISTRATIONS = 'DELETE_REGISTRATIONS';
export const DELETE_REGISTRATIONS_PENDING = 'DELETE_REGISTRATIONS_PENDING';
export const DELETE_REGISTRATIONS_FULFILLED = 'DELETE_REGISTRATIONS_FULFILLED';
export const DELETE_REGISTRATIONS_REJECTED = 'DELETE_REGISTRATIONS_REJECTED';

export const APPROVE_REGISTRATIONS = 'APPROVE_REGISTRATIONS';
export const APPROVE_REGISTRATIONS_PENDING = 'APPROVE_REGISTRATIONS_PENDING';
export const APPROVE_REGISTRATIONS_FULFILLED =
    'APPROVE_REGISTRATIONS_FULFILLED';
export const APPROVE_REGISTRATIONS_REJECTED = 'APPROVE_REGISTRATIONS_REJECTED';

export const FILTER_RESOURCES = 'FILTER_RESOURCES';
export const RESET_RESOURCES = 'RESET_RESOURCES';

export const RESET_QUOTATION_SCREEN = 'RESET_QUOTATION_SCREEN';

export const RESET_QUOTATION = 'RESET_QUOTATION';

export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const SHOW_WARNING = 'SHOW_WARNING';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';

export const HIDE_SUCCESS = 'HIDE_SUCCESS';
export const HIDE_WARNING = 'HIDE_WARNING';
export const HIDE_ERROR = 'HIDE_ERROR';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export const GET_POLICY_REQUEST_PENDING = 'GET_POLICY_REQUEST_PENDING';
export const GET_POLICY_REQUEST_FULFILLED = 'GET_POLICY_REQUEST_FULFILLED';
export const GET_POLICY_REQUEST_REJECTED = 'GET_POLICY_REQUEST_REJECTED';

export const GET_NEWS = 'GET_NEWS';
export const GET_NEWS_PENDING = 'GET_NEWS_PENDING';
export const GET_NEWS_FULFILLED = 'GET_NEWS_FULFILLED';
export const GET_NEWS_REJECTED = 'GET_NEWS_REJECTED';

export const ADD_NEWS = 'ADD_NEWS';
export const ADD_NEWS_PENDING = 'ADD_NEWS_PENDING';
export const ADD_NEWS_FULFILLED = 'ADD_NEWS_FULFILLED';
export const ADD_NEWS_REJECTED = 'ADD_NEWS_REJECTED';

export const UPDATE_NEWS = 'UPDATE_NEWS';
export const UPDATE_NEWS_PENDING = 'UPDATE_NEWS_PENDING';
export const UPDATE_NEWS_FULFILLED = 'UPDATE_NEWS_FULFILLED';
export const UPDATE_NEWS_REJECTED = 'UPDATE_NEWS_REJECTED';

export const DELETE_NEWS = 'DELETE_NEWS';
export const DELETE_NEWS_PENDING = 'DELETE_NEWS_PENDING';
export const DELETE_NEWS_FULFILLED = 'DELETE_NEWS_FULFILLED';
export const DELETE_NEWS_REJECTED = 'DELETE_NEWS_REJECTED';

export const FILTER_NEWS = 'FILTER_NEWS';
export const RESET_NEWS = 'RESET_NEWS';

export const GET_NEWS_BY_SLUG = 'GET_NEWS_BY_SLUG';
export const GET_NEWS_BY_SLUG_PENDING = 'GET_NEWS_BY_SLUG_PENDING';
export const GET_NEWS_BY_SLUG_FULFILLED = 'GET_NEWS_BY_SLUG_FULFILLED';
export const GET_NEWS_BY_SLUG_REJECTED = 'GET_NEWS_BY_SLUG_REJECTED';

export const GET_MODULES = 'GET_MODULES';
export const GET_MODULES_PENDING = 'GET_MODULES_PENDING';
export const GET_MODULES_FULFILLED = 'GET_MODULES_FULFILLED';
export const GET_MODULES_REJECTED = 'GET_MODULES_REJECTED';

export const GET_MODULE_BY_ID = 'GET_MODULE_BY_ID';
export const GET_MODULE_BY_ID_PENDING = 'GET_MODULE_BY_ID_PENDING';
export const GET_MODULE_BY_ID_FULFILLED = 'GET_MODULE_BY_ID_FULFILLED';
export const GET_MODULE_BY_ID_REJECTED = 'GET_MODULE_BY_ID_REJECTED';

export const ADD_MODULE = 'ADD_MODULE';
export const ADD_MODULE_PENDING = 'ADD_MODULE_PENDING';
export const ADD_MODULE_FULFILLED = 'ADD_MODULE_FULFILLED';
export const ADD_MODULE_REJECTED = 'ADD_MODULE_REJECTED';

export const UPDATE_MODULE = 'UPDATE_MODULE';
export const UPDATE_MODULE_PENDING = 'UPDATE_MODULE_PENDING';
export const UPDATE_MODULE_FULFILLED = 'UPDATE_MODULE_FULFILLED';
export const UPDATE_MODULE_REJECTED = 'UPDATE_MODULE_REJECTED';

export const DELETE_MODULE = 'DELETE_MODULE';
export const DELETE_MODULE_PENDING = 'DELETE_MODULE_PENDING';
export const DELETE_MODULE_FULFILLED = 'DELETE_MODULE_FULFILLED';
export const DELETE_MODULE_REJECTED = 'DELETE_MODULE_REJECTED';

export const ADD_DEFINITION_MODULE = 'ADD_DEFINITION_MODULE';
export const UPDATE_DEFINITION_MODULE = 'UPDATE_DEFINITION_MODULE';
export const DELETE_DEFINITION_MODULE = 'DELETE_DEFINITION_MODULE';

export const ADD_EVENT_MODULE = 'ADD_EVENT_MODULE';
export const UPDATE_EVENT_MODULE = 'UPDATE_EVENT_MODULE';
export const DELETE_EVENT_MODULE = 'DELETE_EVENT_MODULE';

export const RESET_MODULE = 'RESET_MODULE';

export const GET_EVENTS_BY_USER_ID = 'GET_EVENTS_BY_USER_ID';
export const GET_EVENTS_BY_USER_ID_PENDING = 'GET_EVENTS_BY_USER_ID_PENDING';
export const GET_EVENTS_BY_USER_ID_FULFILLED =
    'GET_EVENTS_BY_USER_ID_FULFILLED';
export const GET_EVENTS_BY_USER_ID_REJECTED = 'GET_EVENTS_BY_USER_ID_REJECTED';

export const GET_NOTIFICATIONS_BY_USER_ID = 'GET_NOTIFICATIONS_BY_USER_ID';
export const GET_NOTIFICATIONS_BY_USER_ID_PENDING =
    'GET_NOTIFICATIONS_BY_USER_ID_PENDING';
export const GET_NOTIFICATIONS_BY_USER_ID_FULFILLED =
    'GET_NOTIFICATIONS_BY_USER_ID_FULFILLED';
export const GET_NOTIFICATIONS_BY_USER_ID_REJECTED =
    'GET_NOTIFICATIONS_BY_USER_ID_REJECTED';

export const GET_NEW_NOTIFICATION = 'GET_NEW_NOTIFICATION';
export const GET_NEW_NOTIFICATION_PENDING = 'GET_NEW_NOTIFICATION_PENDING';
export const GET_NEW_NOTIFICATION_FULFILLED = 'GET_NEW_NOTIFICATION_FULFILLED';
export const GET_NEW_NOTIFICATION_REJECTED = 'GET_NEW_NOTIFICATION_REJECTED';

export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_NOTIFICATION_PENDING = 'READ_NOTIFICATION_PENDING';
export const READ_NOTIFICATION_FULFILLED = 'READ_NOTIFICATION_FULFILLED';
export const READ_NOTIFICATION_REJECTED = 'READ_NOTIFICATION_REJECTED';

export const SET_EVENT_LISTENERS = 'SET_EVENT_LISTENERS';

export const UPDATE_USER_EVENTS = 'UPDATE_USER_EVENTS';
export const UPDATE_USER_EVENTS_PENDING = 'UPDATE_USER_EVENTS_PENDING';
export const UPDATE_USER_EVENTS_FULFILLED = 'UPDATE_USER_EVENTS_FULFILLED';
export const UPDATE_USER_EVENTS_REJECTED = 'UPDATE_USER_EVENTS_REJECTED';

//Drawer de perfil y sus diferentes opciones
export const GET_USER_INFO_LOGGED = 'GET_USER_INFO_LOGGED';
export const GET_USER_INFO_LOGGED_PENDING = 'GET_USER_INFO_LOGGED_PENDING';
export const GET_USER_INFO_LOGGED_FULFILLED = 'GET_USER_INFO_LOGGED_FULFILLED';
export const GET_USER_INFO_LOGGED_REJECTED = 'GET_USER_INFO_LOGGED_REJECTED';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_PENDING = 'UPDATE_USER_INFO_PENDING';
export const UPDATE_USER_INFO_FULFILLED = 'UPDATE_USER_INFO_FULFILLED';
export const UPDATE_USER_INFO_REJECTED = 'UPDATE_USER_INFO_REJECTED';

export const GET_AGENT_BANK_INFO = 'GET_AGENT_BANK_INFO';
export const GET_AGENT_BANK_INFO_PENDING = 'GET_AGENT_BANK_INFO_PENDING';
export const GET_AGENT_BANK_INFO_FULFILLED = 'GET_AGENT_BANK_INFO_FULFILLED';
export const GET_AGENT_BANK_INFO_REJECTED = 'GET_AGENT_BANK_INFO_REJECTED';

export const CREATE_AGENT_BANK_INFO = 'CREATE_AGENT_BANK_INFO';
export const CREATE_AGENT_BANK_INFO_PENDING = 'CREATE_AGENT_BANK_INFO_PENDING';
export const CREATE_AGENT_BANK_INFO_FULFILLED =
    'CREATE_AGENT_BANK_INFO_FULFILLED';
export const CREATE_AGENT_BANK_INFO_REJECTED =
    'CREATE_AGENT_BANK_INFO_REJECTED';

export const UPDATE_AGENT_BANK_INFO = 'UPDATE_AGENT_BANK_INFO';
export const UPDATE_AGENT_BANK_INFO_PENDING = 'UPDATE_AGENT_BANK_INFO_PENDING';
export const UPDATE_AGENT_BANK_INFO_FULFILLED =
    'UPDATE_AGENT_BANK_INFO_FULFILLED';
export const UPDATE_AGENT_BANK_INFO_REJECTED =
    'UPDATE_AGENT_BANK_INFO_REJECTED';

export const GET_AGENT_DOCUMENT_INFO = 'GET_AGENT_DOCUMENT_INFO';
export const GET_AGENT_DOCUMENT_INFO_PENDING =
    'GET_AGENT_DOCUMENT_INFO_PENDING';
export const GET_AGENT_DOCUMENT_INFO_FULFILLED =
    'GET_AGENT_DOCUMENT_INFO_FULFILLED';
export const GET_AGENT_DOCUMENT_INFO_REJECTED =
    'GET_AGENT_DOCUMENT_INFO_REJECTED';

export const CREATE_AGENT_DOCUMENT_INFO = 'CREATE_AGENT_DOCUMENT_INFO';
export const CREATE_AGENT_DOCUMENT_INFO_PENDING =
    'CREATE_AGENT_DOCUMENT_INFO_PENDING';
export const CREATE_AGENT_DOCUMENT_INFO_FULFILLED =
    'CREATE_AGENT_DOCUMENT_INFO_FULFILLED';
export const CREATE_AGENT_DOCUMENT_INFO_REJECTED =
    'CREATE_AGENT_DOCUMENT_INFO_REJECTED';

export const UPDATE_AGENT_DOCUMENT_INFO = 'UPDATE_AGENT_DOCUMENT_INFO';
export const UPDATE_AGENT_DOCUMENT_INFO_PENDING =
    'UPDATE_AGENT_DOCUMENT_INFO_PENDING';
export const UPDATE_AGENT_DOCUMENT_INFO_FULFILLED =
    'UPDATE_AGENT_DOCUMENT_INFO_FULFILLED';
export const UPDATE_AGENT_DOCUMENT_INFO_REJECTED =
    'UPDATE_AGENT_DOCUMENT_INFO_REJECTED';
export const GET_USER_LOGGED = 'GET_USER_LOGGED';
export const GET_USER_LOGGED_PENDING = 'GET_USER_LOGGED_PENDING';
export const GET_USER_LOGGED_FULFILLED = 'GET_USER_LOGGED_FULFILLED';
export const GET_USER_LOGGED_REJECTED = 'GET_USER_LOGGED_REJECTED';

export const GET_BALANCE_INQUIRY = 'GET_BALANCE_INQUIRY';
export const GET_BALANCE_INQUIRY_PENDING = 'GET_BALANCE_INQUIRY_PENDING';
export const GET_BALANCE_INQUIRY_FULFILLED = 'GET_BALANCE_INQUIRY_FULFILLED';
export const GET_BALANCE_INQUIRY_REJECTED = 'GET_BALANCE_INQUIRY_REJECTED';

export const SHOW_MODAL = 'SHOW_MODAL';
export const SHOW_DRAWER_COMMISSION_PAYMENT = 'SHOW_DRAWER_COMMISSION_PAYMENT';
export const SHOW_DRAWER_PROOF_OF_PAYMENT = 'SHOW_DRAWER_PROOF_OF_PAYMENT';
export const CURRENCY_SELECTED_BALANCE_INQUIRY =
    'CURRENCY_SELECTED_BALANCE_INQUIRY';

export const GET_PROOF_OF_PAYMENT = 'GET_PROOF_OF_PAYMENT';
export const GET_PROOF_OF_PAYMENT_PENDING = 'GET_PROOF_OF_PAYMENT_PENDING';
export const GET_PROOF_OF_PAYMENT_FULFILLED = 'GET_PROOF_OF_PAYMENT_FULFILLED';
export const GET_PROOF_OF_PAYMENT_REJECTED = 'GET_PROOF_OF_PAYMENT_REJECTED';
export const GET_REQUEST_WORKFLOW_PENDING = 'GET_REQUEST_WORKFLOW_PENDING';
export const GET_REQUEST_WORKFLOW_FULFILLED = 'GET_REQUEST_WORKFLOW_FULFILLED';
export const GET_REQUEST_WORKFLOW_REJECTED = 'GET_REQUEST_WORKFLOW_REJECTED';

export const GET_SINGLE_REQUEST_WORKFLOW_PENDING =
    'GET_SINGLE_REQUEST_WORKFLOW_PENDING';
export const GET_SINGLE_REQUEST_WORKFLOW_FULFILLED =
    'GET_SINGLE_REQUEST_WORKFLOW_FULFILLED';
export const GET_SINGLE_REQUEST_WORKFLOW_REJECTED =
    'GET_SINGLE_REQUEST_WORKFLOW_REJECTED';

export const ADD_REQUEST_WORKFLOW = 'ADD_REQUEST_WORKFLOW';
export const ADD_REQUEST_WORKFLOW_PENDING = 'ADD_REQUEST_WORKFLOW_PENDING';
export const ADD_REQUEST_WORKFLOW_FULFILLED = 'ADD_REQUEST_WORKFLOW_FULFILLED';
export const ADD_REQUEST_WORKFLOW_REJECTED = 'ADD_REQUEST_WORKFLOW_REJECTED';

export const UPDATE_REQUEST_WORKFLOW = 'UPDATE_REQUEST_WORKFLOW';
export const UPDATE_REQUEST_WORKFLOW_PENDING =
    'UPDATE_REQUEST_WORKFLOW_PENDING';
export const UPDATE_REQUEST_WORKFLOW_FULFILLED =
    'UPDATE_REQUEST_WORKFLOW_FULFILLED';
export const UPDATE_REQUEST_WORKFLOW_REJECTED =
    'UPDATE_REQUEST_WORKFLOW_REJECTED';

export const DELETE_REQUEST_WORKFLOW = 'DELETE_REQUEST_WORKFLOW';
export const DELETE_REQUEST_WORKFLOW_PENDING =
    'DELETE_REQUEST_WORKFLOW_PENDING';
export const DELETE_REQUEST_WORKFLOW_FULFILLED =
    'DELETE_REQUEST_WORKFLOW_FULFILLED';
export const DELETE_REQUEST_WORKFLOW_REJECTED =
    'DELETE_REQUEST_WORKFLOW_REJECTED';

export const SET_FILTER_REQUEST_WORKFLOW = 'SET_FILTER_REQUEST_WORKFLOW';
export const DELETE_FILTER_REQUEST_WORKFLOW = 'DELETE_FILTER_REQUEST_WORKFLOW';
export const RESET_FILTER_REQUEST_WORKFLOW = 'RESET_FILTER_REQUEST_WORKFLOW';
export const FILTER_REQUEST_WORKFLOW = 'FILTER_REQUEST_WORKFLOW';
export const RESET_SINGLE_REQUEST_WORKFLOW = 'RESET_SINGLE_REQUEST_WORKFLOW';
export const RESET_REQUEST_WORKFLOW = 'RESET_REQUEST_WORKFLOW';

export const SET_FALSE_IS_ASSIGNED_TO_ME = 'SET_FALSE_IS_ASSIGNED_TO_ME';

export const GET_ASSIGNED_TO_ME_REQUESTS = 'GET_ASSIGNED_TO_ME_REQUESTS';
export const GET_ASSIGNED_TO_ME_REQUESTS_PENDING =
    'GET_ASSIGNED_TO_ME_REQUESTS_PENDING';
export const GET_ASSIGNED_TO_ME_REQUESTS_FULFILLED =
    'GET_ASSIGNED_TO_ME_REQUESTS_FULFILLED';
export const GET_ASSIGNED_TO_ME_REQUESTS_REJECTED =
    'GET_ASSIGNED_TO_ME_REQUESTS_REJECTED';

export const ADD_MASSIVE_COLLECTIVE_REQUEST = 'ADD_MASSIVE_COLLECTIVE_REQUEST';
export const ADD_MASSIVE_COLLECTIVE_REQUEST_PENDING =
    'ADD_MASSIVE_COLLECTIVE_REQUEST_PENDING';
export const ADD_MASSIVE_COLLECTIVE_REQUEST_FULFILLED =
    'ADD_MASSIVE_COLLECTIVE_REQUEST_FULFILLED';
export const ADD_MASSIVE_COLLECTIVE_REQUEST_REJECTED =
    'ADD_MASSIVE_COLLECTIVE_REQUEST_REJECTED';

export const GET_MASSIVE_COLLECTIVE_REQUEST_PENDING =
    'GET_MASSIVE_COLLECTIVE_REQUEST_PENDING';
export const GET_MASSIVE_COLLECTIVE_REQUEST_FULFILLED =
    'GET_MASSIVE_COLLECTIVE_REQUEST_FULFILLED';
export const GET_MASSIVE_COLLECTIVE_REQUEST_REJECTED =
    'GET_MASSIVE_COLLECTIVE_REQUEST_REJECTED';

export const GET_COLLECTIVE_REQUEST_IN = 'GET_COLLECTIVE_REQUEST_IN';
export const GET_COLLECTIVE_REQUEST_IN_PENDING =
    'GET_COLLECTIVE_REQUEST_IN_PENDING';
export const GET_COLLECTIVE_REQUEST_IN_FULFILLED =
    'GET_COLLECTIVE_REQUEST_IN_FULFILLED';
export const GET_COLLECTIVE_REQUEST_IN_REJECTED =
    'GET_COLLECTIVE_REQUEST_IN_REJECTED';

export const SET_UNAVAILABLE_COLLECTIVE_REQUEST =
    'SET_UNAVAILABLE_COLLECTIVE_REQUEST';
export const SET_UNAVAILABLE_COLLECTIVE_REQUEST_PENDING =
    'SET_UNAVAILABLE_COLLECTIVE_REQUEST_PENDING';
export const SET_UNAVAILABLE_COLLECTIVE_REQUEST_FULFILLED =
    'SET_UNAVAILABLE_COLLECTIVE_REQUEST_FULFILLED';
export const SET_UNAVAILABLE_COLLECTIVE_REQUEST_REJECTED =
    'SET_UNAVAILABLE_COLLECTIVE_REQUEST_REJECTED';

export const UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST =
    'UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST';
export const UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_PENDING =
    'UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_PENDING';
export const UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_FULFILLED =
    'UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_FULFILLED';
export const UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_REJECTED =
    'UPDATE_LAST_SEND_DATE_COLLECTIVE_REQUEST_REJECTED';

export const UPDATE_MASSIVE_COLLECTIVE_REQUEST_PENDING =
    'UPDATE_MASSIVE_COLLECTIVE_REQUEST_PENDING';
export const UPDATE_MASSIVE_COLLECTIVE_REQUEST_FULFILLED =
    'UPDATE_MASSIVE_COLLECTIVE_REQUEST_FULFILLED';
export const UPDATE_MASSIVE_COLLECTIVE_REQUEST_REJECTED =
    'UPDATE_MASSIVE_COLLECTIVE_REQUEST_REJECTED';
export const DELETE_MASSIVE_COLLECTIVE_REQUEST_PENDING =
    'DELETE_MASSIVE_COLLECTIVE_REQUEST_PENDING';
export const DELETE_MASSIVE_COLLECTIVE_REQUEST_FULFILLED =
    'DELETE_MASSIVE_COLLECTIVE_REQUEST_FULFILLED';
export const DELETE_MASSIVE_COLLECTIVE_REQUEST_REJECTED =
    'DELETE_MASSIVE_COLLECTIVE_REQUEST_REJECTED';
export const FILTER_MASSIVE_COLLECTIVE_REQUEST =
    'FILTER_MASSIVE_COLLECTIVE_REQUEST';
export const RESET_MASSIVE_COLLECTIVE_REQUEST =
    'RESET_MASSIVE_COLLECTIVE_REQUEST';

export const GET_WORKFLOW = 'GET_WORKFLOW';
export const GET_WORKFLOW_PENDING = 'GET_WORKFLOW_PENDING';
export const GET_WORKFLOW_FULFILLED = 'GET_WORKFLOW_FULFILLED';
export const GET_WORKFLOW_REJECTED = 'GET_WORKFLOW_REJECTED';

export const GET_ITEM_REQUEST_WORKFLOW_PENDING =
    'GET_ITEM_REQUEST_WORKFLOW_PENDING';
export const GET_ITEM_REQUEST_WORKFLOW_FULFILLED =
    'GET_ITEM_REQUEST_WORKFLOW_FULFILLED';
export const GET_ITEM_REQUEST_WORKFLOW_REJECTED =
    'GET_ITEM_REQUEST_WORKFLOW_REJECTED';
export const ADD_ITEM_REQUEST_WORKFLOW_PENDING =
    'ADD_ITEM_REQUEST_WORKFLOW_PENDING';
export const ADD_ITEM_REQUEST_WORKFLOW_FULFILLED =
    'ADD_ITEM_REQUEST_WORKFLOW_FULFILLED';
export const ADD_ITEM_REQUEST_WORKFLOW_REJECTED =
    'ADD_ITEM_REQUEST_WORKFLOW_REJECTED';
export const UPDATE_ITEM_REQUEST_WORKFLOW_PENDING =
    'UPDATE_ITEM_REQUEST_WORKFLOW_PENDING';
export const UPDATE_ITEM_REQUEST_WORKFLOW_FULFILLED =
    'UPDATE_ITEM_REQUEST_WORKFLOW_FULFILLED';
export const UPDATE_ITEM_REQUEST_WORKFLOW_REJECTED =
    'UPDATE_ITEM_REQUEST_WORKFLOW_REJECTED';
export const DELETE_ITEM_REQUEST_WORKFLOW_PENDING =
    'DELETE_ITEM_REQUEST_WORKFLOW_PENDING';
export const DELETE_ITEM_REQUEST_WORKFLOW_FULFILLED =
    'DELETE_ITEM_REQUEST_WORKFLOW_FULFILLED';
export const DELETE_ITEM_REQUEST_WORKFLOW_REJECTED =
    'DELETE_ITEM_REQUEST_WORKFLOW_REJECTED';
export const FILTER_ITEM_REQUEST_WORKFLOW = 'FILTER_ITEM_REQUEST_WORKFLOW';
export const RESET_ITEM_REQUEST_WORKFLOW = 'RESET_ITEM_REQUEST_WORKFLOW';

export const GET_COMMENT_REQUEST_WORKFLOW = 'GET_COMMENT_REQUEST_WORKFLOW';
export const GET_COMMENT_REQUEST_WORKFLOW_PENDING =
    'GET_COMMENT_REQUEST_WORKFLOW_PENDING';
export const GET_COMMENT_REQUEST_WORKFLOW_FULFILLED =
    'GET_COMMENT_REQUEST_WORKFLOW_FULFILLED';
export const GET_COMMENT_REQUEST_WORKFLOW_REJECTED =
    'GET_COMMENT_REQUEST_WORKFLOW_REJECTED';

export const GET_COMMENT_REQUEST_WORKFLOW_BY_ID =
    'GET_COMMENT_REQUEST_WORKFLOW_BY_ID';
export const GET_COMMENT_REQUEST_WORKFLOW_BY_ID_PENDING =
    'GET_COMMENT_REQUEST_WORKFLOW_BY_ID_PENDING';
export const GET_COMMENT_REQUEST_WORKFLOW_BY_ID_FULFILLED =
    'GET_COMMENT_REQUEST_WORKFLOW_BY_ID_FULFILLED';
export const GET_COMMENT_REQUEST_WORKFLOW_BY_ID_REJECTED =
    'GET_COMMENT_REQUEST_WORKFLOW_BY_ID_REJECTED';

export const ADD_COMMENT_REQUEST_WORKFLOW = 'ADD_COMMENT_REQUEST_WORKFLOW';
export const ADD_COMMENT_REQUEST_WORKFLOW_PENDING =
    'ADD_COMMENT_REQUEST_WORKFLOW_PENDING';
export const ADD_COMMENT_REQUEST_WORKFLOW_FULFILLED =
    'ADD_COMMENT_REQUEST_WORKFLOW_FULFILLED';
export const ADD_COMMENT_REQUEST_WORKFLOW_REJECTED =
    'ADD_COMMENT_REQUEST_WORKFLOW_REJECTED';

export const UPDATE_COMMENT_REQUEST_WORKFLOW =
    'UPDATE_COMMENT_REQUEST_WORKFLOW';
export const UPDATE_COMMENT_REQUEST_WORKFLOW_PENDING =
    'UPDATE_COMMENT_REQUEST_WORKFLOW_PENDING';
export const UPDATE_COMMENT_REQUEST_WORKFLOW_FULFILLED =
    'UPDATE_COMMENT_REQUEST_WORKFLOW_FULFILLED';
export const UPDATE_COMMENT_REQUEST_WORKFLOW_REJECTED =
    'UPDATE_COMMENT_REQUEST_WORKFLOW_REJECTED';

export const DELETE_COMMENT_REQUEST_WORKFLOW =
    'DELETE_COMMENT_REQUEST_WORKFLOW';
export const DELETE_COMMENT_REQUEST_WORKFLOW_PENDING =
    'DELETE_COMMENT_REQUEST_WORKFLOW_PENDING';
export const DELETE_COMMENT_REQUEST_WORKFLOW_FULFILLED =
    'DELETE_COMMENT_REQUEST_WORKFLOW_FULFILLED';
export const DELETE_COMMENT_REQUEST_WORKFLOW_REJECTED =
    'DELETE_COMMENT_REQUEST_WORKFLOW_REJECTED';

export const FILTER_COMMENT_REQUEST_WORKFLOW =
    'FILTER_COMMENT_REQUEST_WORKFLOW';
export const RESET_COMMENT_REQUEST_WORKFLOW = 'RESET_COMMENT_REQUEST_WORKFLOW';

export const GET_TRACKING_REQUEST_WORKFLOW = 'GET_TRACKING_REQUEST_WORKFLOW';
export const GET_TRACKING_REQUEST_WORKFLOW_PENDING =
    'GET_TRACKING_REQUEST_WORKFLOW_PENDING';
export const GET_TRACKING_REQUEST_WORKFLOW_FULFILLED =
    'GET_TRACKING_REQUEST_WORKFLOW_FULFILLED';
export const GET_TRACKING_REQUEST_WORKFLOW_REJECTED =
    'GET_TRACKING_REQUEST_WORKFLOW_REJECTED';

export const GET_TRACKING_REQUEST_WORKFLOW_BY_ID =
    'GET_TRACKING_REQUEST_WORKFLOW_BY_ID';
export const GET_TRACKING_REQUEST_WORKFLOW_BY_ID_PENDING =
    'GET_TRACKING_REQUEST_WORKFLOW_BY_ID_PENDING';
export const GET_TRACKING_REQUEST_WORKFLOW_BY_ID_FULFILLED =
    'GET_TRACKING_REQUEST_WORKFLOW_BY_ID_FULFILLED';
export const GET_TRACKING_REQUEST_WORKFLOW_BY_ID_REJECTED =
    'GET_TRACKING_REQUEST_WORKFLOW_BY_ID_REJECTED';

export const ADD_TRACKING_REQUEST_WORKFLOW = 'ADD_TRACKING_REQUEST_WORKFLOW';
export const ADD_TRACKING_REQUEST_WORKFLOW_PENDING =
    'ADD_TRACKING_REQUEST_WORKFLOW_PENDING';
export const ADD_TRACKING_REQUEST_WORKFLOW_FULFILLED =
    'ADD_TRACKING_REQUEST_WORKFLOW_FULFILLED';
export const ADD_TRACKING_REQUEST_WORKFLOW_REJECTED =
    'ADD_TRACKING_REQUEST_WORKFLOW_REJECTED';

export const UPDATE_TRACKING_REQUEST_WORKFLOW =
    'UPDATE_TRACKING_REQUEST_WORKFLOW';
export const UPDATE_TRACKING_REQUEST_WORKFLOW_PENDING =
    'UPDATE_TRACKING_REQUEST_WORKFLOW_PENDING';
export const UPDATE_TRACKING_REQUEST_WORKFLOW_FULFILLED =
    'UPDATE_TRACKING_REQUEST_WORKFLOW_FULFILLED';
export const UPDATE_TRACKING_REQUEST_WORKFLOW_REJECTED =
    'UPDATE_TRACKING_REQUEST_WORKFLOW_REJECTED';

export const LOCK_SAVE_BUTTON_REQUESTWF = 'LOCK_SAVE_BUTTON_REQUESTWF';
export const INCREASE_ROW_COUNTER_IN_TABLE = 'INCREASE_ROW_COUNTER_IN_TABLE';
export const DECREMENT_ROW_COUNTER_IN_TABLE = 'DECREMENT_ROW_COUNTER_IN_TABLE';
export const RESET_ROW_COUNTER_IN_TABLE = 'RESET_ROW_COUNTER_IN_TABLE';

export const UPLOAD_FILES = 'UPLOAD_FILES';
export const UPLOAD_FILES_PENDING = 'UPLOAD_FILES_PENDING';
export const UPLOAD_FILES_FULFILLED = 'UPLOAD_FILES_FULFILLED';
export const UPLOAD_FILES_REJECTED = 'UPLOAD_FILES_REJECTED';
export const SET_PREVIOUS_FILES = 'SET_PREVIOUS_FILES';
export const RESET_UPLOAD_FILES = 'RESET_UPLOAD_FILES';
export const RESET_UPLOAD_TEMPORAL_FILES = 'RESET_UPLOAD_TEMPORAL_FILES';
export const SET_FILTERED_UPLOAD_FILES = 'SET_FILTERED_UPLOAD_FILES';

export const REPLACE_FILES = 'REPLACE_FILES';
export const REPLACE_FILES_PENDING = 'REPLACE_FILES_PENDING';
export const REPLACE_FILES_FULFILLED = 'REPLACE_FILES_FULFILLED';
export const REPLACE_FILES_REJECTED = 'REPLACE_FILES_REJECTED';

export const GET_WORKFLOW_ACTIONS = 'GET_WORKFLOW_ACTIONS';
export const GET_WORKFLOW_ACTIONS_PENDING = 'GET_WORKFLOW_ACTIONS_PENDING';
export const GET_WORKFLOW_ACTIONS_FULFILLED = 'GET_WORKFLOW_ACTIONS_FULFILLED';
export const GET_WORKFLOW_ACTIONS_REJECTED = 'GET_WORKFLOW_ACTIONS_REJECTED';

export const GET_WORKFLOW_TYPES = 'GET_WORKFLOW_TYPES';
export const GET_WORKFLOW_TYPES_PENDING = 'GET_WORKFLOW_TYPES_PENDING';
export const GET_WORKFLOW_TYPES_FULFILLED = 'GET_WORKFLOW_TYPES_FULFILLED';
export const GET_WORKFLOW_TYPES_REJECTED = 'GET_WORKFLOW_TYPES_REJECTED';

export const RESET_STATES = 'RESET_STATES';
export const RESET_ACTIONS = 'RESET_ACTIONS';

export const GET_WORKFLOW_NEXT_STATE = 'GET_WORKFLOW_NEXT_STATE';
export const GET_WORKFLOW_NEXT_STATE_PENDING =
    'GET_WORKFLOW_NEXT_STATE_PENDING';
export const GET_WORKFLOW_NEXT_STATE_FULFILLED =
    'GET_WORKFLOW_NEXT_STATE_FULFILLED';
export const GET_WORKFLOW_NEXT_STATE_REJECTED =
    'GET_WORKFLOW_NEXT_STATE_REJECTED';

export const GET_WORKFLOW_STATES = 'GET_WORKFLOW_STATES';
export const GET_WORKFLOW_STATES_PENDING = 'GET_WORKFLOW_STATES_PENDING';
export const GET_WORKFLOW_STATES_FULFILLED = 'GET_WORKFLOW_STATES_FULFILLED';
export const GET_WORKFLOW_STATES_REJECTED = 'GET_WORKFLOW_STATES_REJECTED';

export const SAVE_TEMPORAL_FILES = 'SAVE_TEMPORAL_FILES';
export const SAVE_TEMPORAL_FILES_PENDING = 'SAVE_TEMPORAL_FILES_PENDING';
export const SAVE_TEMPORAL_FILES_FULFILLED = 'SAVE_TEMPORAL_FILES_FULFILLED';
export const SAVE_TEMPORAL_FILES_REJECTED = 'SAVE_TEMPORAL_FILES_REJECTED';

export const GET_REQUEST_WORKFLOW_GROUP = 'GET_REQUEST_WORKFLOW_GROUP';
export const GET_REQUEST_WORKFLOW_GROUP_PENDING =
    'GET_REQUEST_WORKFLOW_GROUP_PENDING';
export const GET_REQUEST_WORKFLOW_GROUP_FULFILLED =
    'GET_REQUEST_WORKFLOW_GROUP_FULFILLED';
export const GET_REQUEST_WORKFLOW_GROUP_REJECTED =
    'GET_REQUEST_WORKFLOW_GROUP_REJECTED';

export const GET_Q_SISE_DASHBOARD = 'GET_Q_SISE_DASHBOARD';
export const GET_Q_SISE_DASHBOARD_PENDING = 'GET_Q_SISE_DASHBOARD_PENDING';
export const GET_Q_SISE_DASHBOARD_FULFILLED = 'GET_Q_SISE_DASHBOARD_FULFILLED';
export const GET_Q_SISE_DASHBOARD_REJECTED = 'GET_Q_SISE_DASHBOARD_REJECTED';

export const GET_AWARDS = 'GET_AWARDS';
export const GET_AWARDS_PENDING = 'GET_AWARDS_PENDING';
export const GET_AWARDS_FULFILLED = 'GET_AWARDS_FULFILLED';
export const GET_AWARDS_REJECTED = 'GET_AWARDS_REJECTED';

export const GET_EMERGENCY = 'GET_EMERGENCY';
export const GET_EMERGENCY_PENDING = 'GET_EMERGENCY_PENDING';
export const GET_EMERGENCY_FULFILLED = 'GET_EMERGENCY_FULFILLED';
export const GET_EMERGENCY_REJECTED = 'GET_EMERGENCY_REJECTED';

export const ADD_IMAGE_COLLECTIVE = 'ADD_IMAGE_COLLECTIVE';
export const SET_USER_COLLECTIVE = 'SET_USER_COLLECTIVE';
export const RESET_IMAGES = 'RESET_IMAGES';

export const GET_CONFIGURATION = 'GET_CONFIGURATION';
export const GET_CONFIGURATION_PENDING = 'GET_CONFIGURATION_PENDING';
export const GET_CONFIGURATION_FULFILLED = 'GET_CONFIGURATION_FULFILLED';
export const GET_CONFIGURATION_REJECTED = 'GET_CONFIGURATION_REJECTED';

export const GET_BANKS = 'GET_BANKS';
export const GET_BANKS_PENDING = 'GET_BANKS_PENDING';
export const GET_BANKS_FULFILLED = 'GET_BANKS_FULFILLED';
export const GET_BANKS_REJECTED = 'GET_BANKS_REJECTED';

export const GET_ACCOUNT_TYPES = 'GET_ACCOUNT_TYPES';
export const GET_ACCOUNT_TYPES_PENDING = 'GET_ACCOUNT_TYPES_PENDING';
export const GET_ACCOUNT_TYPES_FULFILLED = 'GET_ACCOUNT_TYPES_FULFILLED';
export const GET_ACCOUNT_TYPES_REJECTED = 'GET_ACCOUNT_TYPES_REJECTED';

export const GET_MEDICAL_NETWORK_SERVICES = 'GET_MEDICAL_NETWORK_SERVICES';
export const GET_MEDICAL_NETWORK_SERVICES_PENDING =
    'GET_MEDICAL_NETWORK_SERVICES_PENDING';
export const GET_MEDICAL_NETWORK_SERVICES_FULFILLED =
    'GET_MEDICAL_NETWORK_SERVICES_FULFILLED';
export const GET_MEDICAL_NETWORK_SERVICES_REJECTED =
    'GET_MEDICAL_NETWORK_SERVICES_REJECTED';

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const GET_USER_CONFIG = 'GET_USER_CONFIG';
export const GET_USER_CONFIG_PENDING = 'GET_USER_CONFIG_PENDING';
export const GET_USER_CONFIG_FULFILLED = 'GET_USER_CONFIG_FULFILLED';
export const GET_USER_CONFIG_REJECTED = 'GET_USER_CONFIG_REJECTED';

export const GET_BONUS_SUMMARY = 'GET_BONUS_SUMMARY';
export const GET_BONUS_SUMMARY_PENDING = 'GET_BONUS_SUMMARY_PENDING';
export const GET_BONUS_SUMMARY_FULFILLED = 'GET_BONUS_SUMMARY_FULFILLED';
export const GET_BONUS_SUMMARY_REJECTED = 'GET_BONUS_SUMMARY_REJECTED';

export const CREATE_DIRECT_EMISSION = 'CREATE_DIRECT_EMISSION';
export const CREATE_DIRECT_EMISSION_PENDING = 'CREATE_DIRECT_EMISSION_PENDING';
export const CREATE_DIRECT_EMISSION_FULFILLED =
    'CREATE_DIRECT_EMISSION_FULFILLED';
export const CREATE_DIRECT_EMISSION_REJECTED =
    'CREATE_DIRECT_EMISSION_REJECTED';

export const REMOVE_ALL_QUOTE = 'REMOVE_ALL_QUOTE';

export const ADD_TAG_CAR = 'ADD_TAG_CAR';
export const ADD_TAG_CONTACT = 'ADD_TAG_CONTACT';
export const ADD_TAG_ACC = 'ADD_TAG_ACC';
export const REMOVE_TAG_CAR = 'REMOVE_TAG_CAR';
export const REMOVE_TAG_CONTACT = 'REMOVE_TAG_CONTACT';
export const REMOVE_TAG_ACC = 'REMOVE_TAG_ACC';
export const PATCH_DATA_LIST = 'PATCH_DATA_LIST';
export const PATCH_PLAN = 'PATCH_PLAN';
export const PATCH_MAIL = 'PATCH_MAIL';
export const PATCH_QUOTATION_ERROR = 'PATCH_QUOTATION_ERROR';

export const GET_LEAD_AND_QUOTATION_PENDING = 'GET_LEAD_AND_QUOTATION_PENDING';
export const GET_LEAD_AND_QUOTATION_FULFILLED =
    'GET_LEAD_AND_QUOTATION_FULFILLED';
export const GET_LEAD_AND_QUOTATION_REJECTED =
    'GET_LEAD_AND_QUOTATION_REJECTED';

export const GET_QUOTATION_BY_ID = 'GET_QUOTATION_BY_ID';
export const GET_QUOTATION_BY_ID_PENDING = 'GET_QUOTATION_BY_ID_PENDING';
export const GET_QUOTATION_BY_ID_FULFILLED = 'GET_QUOTATION_BY_ID_FULFILLED';
export const GET_QUOTATION_BY_ID_REJECTED = 'GET_QUOTATION_BY_ID_REJECTED';

export const POST_SAVE_QUOTATION_PENDING = 'POST_SAVE_QUOTATION_PENDING';
export const POST_SAVE_QUOTATION_FULFILLED = 'POST_SAVE_QUOTATION_FULFILLED';
export const POST_SAVE_QUOTATION_REJECTED = 'POST_SAVE_QUOTATION_REJECTED';

export const POST_UPDATE_QUOTATION_PENDING = 'POST_UPDATE_QUOTATION_PENDING';
export const POST_UPDATE_QUOTATION_FULFILLED =
    'POST_UPDATE_QUOTATION_FULFILLED';
export const POST_UPDATE_QUOTATION_REJECTED = 'POST_UPDATE_QUOTATION_REJECTED';

export const POST_UPDATE_QUOTATION_SCREEN_VALUES =
    'POST_UPDATE_QUOTATION_SCREEN_VALUES';
export const POST_UPDATE_QUOTATION_SCREEN_VALUES_PENDING =
    'POST_UPDATE_QUOTATION_SCREEN_VALUES_PENDING';
export const POST_UPDATE_QUOTATION_SCREEN_VALUES_FULFILLED =
    'POST_UPDATE_QUOTATION_SCREEN_VALUES_FULFILLED';
export const POST_UPDATE_QUOTATION_SCREEN_VALUES_REJECTED =
    'POST_UPDATE_QUOTATION_SCREEN_VALUES_REJECTED';

export const RECOVER_QUOTATION = 'RECOVER_QUOTATION';
export const SUCCESS_QUOTE = 'SUCCESS_QUOTE';

export const GET_YEARS_PENDING = 'GET_YEARS_PENDING';
export const GET_YEARS_FULFILLED = 'GET_YEARS_FULFILLED';
export const GET_YEARS_REJECTED = 'GET_YEARS_REJECTED';
export const PATCH_YEARS_ERROR = 'PATCH_YEARS_ERROR';
export const SET_0KM_YEARS = 'SET_0KM_YEARS';

export const GET_BANK_LIST_PENDING = 'GET_BANK_LIST_PENDING';
export const GET_BANK_LIST_FULFILLED = 'GET_BANK_LIST_FULFILLED';
export const GET_BANK_LIST_REJECTED = 'GET_BANK_LIST_REJECTED';
export const PATCH_BANK_LIST_ERROR = 'PATCH_BANK_LIST_ERROR';

export const GET_CARD_LIST_PENDING = 'GET_CARD_LIST_PENDING';
export const GET_CARD_LIST_FULFILLED = 'GET_CARD_LIST_FULFILLED';
export const GET_CARD_LIST_REJECTED = 'GET_CARD_LIST_REJECTED';
export const PATCH_CARD_LIST_ERROR = 'PATCH_CARD_LIST_ERROR';

export const GET_PAYMENT_METHOD_LIST_PENDING =
    'GET_PAYMENT_METHOD_LIST_PENDING';
export const GET_PAYMENT_METHOD_LIST_FULFILLED =
    'GET_PAYMENT_METHOD_LIST_FULFILLED';
export const GET_PAYMENT_METHOD_LIST_REJECTED =
    'GET_PAYMENT_METHOD_LIST_REJECTED';
export const PATCH_PAYMENT_METHOD_LIST_ERROR =
    'PATCH_PAYMENT_METHOD_LIST_ERROR';

export const UPDATE_PAYMENT_METHOD_PENDING = 'UPDATE_PAYMENT_METHOD_PENDING';
export const UPDATE_PAYMENT_METHOD_FULFILLED =
    'UPDATE_PAYMENT_METHOD_FULFILLED';
export const UPDATE_PAYMENT_METHOD_REJECTED = 'UPDATE_PAYMENT_METHOD_REJECTED';
export const UPDATE_PAYMENT_METHOD_ERROR = 'UPDATE_PAYMENT_METHOD_ERROR';

export const GET_REASONS_PENDING = 'GET_REASONS_PENDING';
export const GET_REASONS_FULFILLED = 'GET_REASONS_FULFILLED';
export const GET_REASONS_ERROR = 'GET_REASONS_ERROR';

export const GENERATE_CANCEL_PENDING = 'GENERATE_CANCEL_PENDING';
export const GENERATE_CANCEL_FULFILLED = 'GENERATE_CANCEL_FULFILLED';
export const GENERATE_CANCEL_ERROR = 'GENERATE_CANCEL_ERROR';

export const GET_DOCUMENTS_LIST_PENDING = 'GET_DOCUMENTS_LIST_PENDING';
export const GET_DOCUMENTS_LIST_FULFILLED = 'GET_DOCUMENTS_LIST_FULFILLED';
export const GET_DOCUMENTS_LIST_REJECTED = 'GET_DOCUMENTS_LIST_REJECTED';

export const GET_FILES_PROPOSAL_PRODUCT = 'GET_FILES_PROPOSAL_PRODUCT';
export const GET_FILES_PROPOSAL_PRODUCT_PENDING =
    'GET_FILES_PROPOSAL_PRODUCT_PENDING';
export const GET_FILES_PROPOSAL_PRODUCT_FULFILLED =
    'GET_FILES_PROPOSAL_PRODUCT_FULFILLED';
export const GET_FILES_PROPOSAL_PRODUCT_REJECTED =
    'GET_FILES_PROPOSAL_PRODUCT_REJECTED';

export const GET_BRANDS_PENDING = 'GET_BRANDS_PENDING';
export const GET_BRANDS_FULFILLED = 'GET_BRANDS_FULFILLED';
export const GET_BRANDS_REJECTED = 'GET_BRANDS_REJECTED';
export const PATCH_BRANDS_ERROR = 'PATCH_BRANDS_ERROR';

export const GET_MODELS_PENDING = 'GET_MODELS_PENDING';
export const GET_MODELS_FULFILLED = 'GET_MODELS_FULFILLED';
export const GET_MODELS_REJECTED = 'GET_MODELS_REJECTED';
export const PATCH_MODELS_ERROR = 'PATCH_MODELS_ERROR';

export const GET_VERSIONS_PENDING = 'GET_VERSIONS_PENDING';
export const GET_VERSIONS_FULFILLED = 'GET_VERSIONS_FULFILLED';
export const GET_VERSIONS_REJECTED = 'GET_VERSIONS_REJECTED';
export const PATCH_VERSIONS_ERROR = 'PATCH_VERSIONS_ERROR';

export const GET_ACCESORIES_PENDING = 'GET_ACCESORIES_PENDING';
export const GET_ACCESORIES_FULFILLED = 'GET_ACCESORIES_FULFILLED';
export const GET_ACCESORIES_REJECTED = 'GET_ACCESORIES_REJECTED';
export const PATCH_ACCESORIES_ERROR = 'PATCH_ACCESORIES_ERROR';

export const GET_INSURANCE_ID_PENDING = 'GET_INSURANCE_ID_PENDING';
export const GET_INSURANCE_ID_FULFILLED = 'GET_INSURANCE_ID_FULFILLED';
export const GET_INSURANCE_ID_REJECTED = 'GET_INSURANCE_ID_REJECTED';
export const RESET_INSURANCE = 'RESET_INSURANCE';

export const GET_AGENT_LIST = 'GET_AGENT_LIST';
export const GET_AGENT_LIST_PENDING = 'GET_AGENT_LIST_PENDING';
export const GET_AGENT_LIST_FULFILLED = 'GET_AGENT_LIST_FULFILLED';
export const GET_AGENT_LIST_REJECTED = 'GET_AGENT_LIST_REJECTED';

export const GET_STATE_LIST = 'GET_STATE_LIST';
export const GET_STATE_LIST_PENDING = 'GET_STATE_LIST_PENDING';
export const GET_STATE_LIST_FULFILLED = 'GET_STATE_LIST_FULFILLED';
export const GET_STATE_LIST_REJECTED = 'GET_STATE_LIST_REJECTED';
export const RESET_STATE_LIST = 'RESET_STATE_LIST';

export const GET_OCUPATIONS = 'GET_OCUPATIONS';
export const GET_OCUPATIONS_PENDING = 'GET_OCUPATIONS_PENDING';
export const GET_OCUPATIONS_FULFILLED = 'GET_OCUPATIONS_FULFILLED';
export const GET_OCUPATIONS_REJECTED = 'GET_OCUPATIONS_REJECTED';

export const GET_POSTAL_CODE = 'GET_POSTAL_CODE';
export const GET_POSTAL_CODE_PENDING = 'GET_POSTAL_CODE_PENDING';
export const GET_POSTAL_CODE_FULFILLED = 'GET_POSTAL_CODE_FULFILLED';
export const GET_POSTAL_CODE_REJECTED = 'GET_POSTAL_CODE_REJECTED';

export const GET_POLICY_LIST = 'GET_POLICY_LIST';
export const GET_POLICY_LIST_PENDING = 'GET_POLICY_LIST_PENDING';
export const GET_POLICY_LIST_FULFILLED = 'GET_POLICY_LIST_FULFILLED';
export const GET_POLICY_LIST_REJECTED = 'GET_POLICY_LIST_REJECTED';

export const GET_FISCAL_CONDITION = 'GET_FISCAL_CONDITION';
export const GET_FISCAL_CONDITION_PENDING = 'GET_FISCAL_CONDITION_PENDING';
export const GET_FISCAL_CONDITION_FULFILLED = 'GET_FISCAL_CONDITION_FULFILLED';
export const GET_FISCAL_CONDITION_REJECTED = 'GET_FISCAL_CONDITION_REJECTED';

export const POST_LEAD_PENDING = 'POST_LEAD_PENDING';
export const POST_LEAD_FULFILLED = 'POST_LEAD_FULFILLED';
export const POST_LEAD_REJECTED = 'POST_LEAD_REJECTED';

export const UPDATE_LEAD_PENDING = 'UPDATE_LEAD_PENDING';
export const UPDATE_LEAD_FULFILLED = 'UPDATE_LEAD_FULFILLED';
export const UPDATE_LEAD_REJECTED = 'UPDATE_LEAD_REJECTED';
export const CLEAN_LEAD = 'CLEAN_LEAD';

export const SET_LEAD = 'SET_LEAD';

export const POST_ENDORSEMENT = 'POST_ENDORSEMENT';
export const POST_ENDORSEMENT_FULFILLED = 'POST_ENDORSEMENT_FULFILLED';
export const POST_ENDORSEMENT_PENDING = 'POST_ENDORSEMENT_PENDING';
export const POST_ENDORSEMENT_REJECTED = 'POST_ENDORSEMENT_REJECTED';

export const PUT_ENDORSEMENT = 'PUT_ENDORSEMENT';
export const PUT_ENDORSEMENT_FULFILLED = 'PUT_ENDORSEMENT_FULFILLED';
export const PUT_ENDORSEMENT_PENDING = 'PUT_ENDORSEMENT_PENDING';
export const PUT_ENDORSEMENT_REJECTED = 'PUT_ENDORSEMENT_REJECTED';

export const SEND_MAIL = 'SEND_MAIL';
export const SEND_MAIL_FULFILLED = 'SEND_MAIL_FULFILLED';
export const SEND_MAIL_PENDING = 'SEND_MAIL_PENDING';
export const SEND_MAIL_REJECTED = 'SEND_MAIL_REJECTED';

export const GET_LEAD_COMMENT_PENDING = 'GET_LEAD_COMMENT_PENDING';
export const GET_LEAD_COMMENT_FULFILLED = 'GET_LEAD_COMMENT_FULFILLED';
export const GET_LEAD_COMMEN_REJECTED = 'GET_LEAD_COMMEN_REJECTED';

export const GET_FILES_SINISTER_PENDING = 'GET_FILES_SINISTER_PENDING';
export const GET_FILES_SINISTER_FULFILLED = 'GET_FILES_SINISTER_FULFILLED';
export const GET_FILES_SINISTER_REJECTED = 'GET_FILES_SINISTER_REJECTED';

export const GET_DOCUMENTS_PROPOSAL = 'GET_DOCUMENTS_PROPOSAL';
export const GET_DOCUMENTS_PROPOSAL_PENDING = 'GET_DOCUMENTS_PROPOSAL_PENDING';
export const GET_DOCUMENTS_PROPOSAL_FULFILLED =
    'GET_DOCUMENTS_PROPOSAL_FULFILLED';
export const GET_DOCUMENTS_PROPOSAL_REJECTED =
    'GET_DOCUMENTS_PROPOSAL_REJECTED';

export const GET_FAQS = 'GET_FAQS';
export const GET_MEDICAL_NETWORK_ATTENTION_POINT_TYPES =
    'GET_MEDICAL_NETWORK_ATTENTION_POINT_TYPES';
export const GET_Q_SISE_SINISTER_REJECTED = 'GET_Q_SISE_SINISTER_REJECTED';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GROUP_MENU_OPENED = 'GROUP_MENU_OPENED';
export const MENU_OPTION = 'MENU_OPTION';
export const RESET_PAYMENT_METHODS = 'RESET_PAYMENT_METHODS';
export const FILTER_PAYMENT_METHODS = 'FILTER_PAYMENT_METHODS';
export const GET_FAQS_FULFILLED = 'GET_FAQS_FULFILLED';
export const GET_PAYMENT_METHODS_FULFILLED = 'GET_PAYMENT_METHODS_FULFILLED';
export const GET_PAYMENT_METHODS_PENDING = 'GET_PAYMENT_METHODS_PENDING';
export const GET_PAYMENT_METHODS_REJECTED = 'GET_PAYMENT_METHODS_REJECTED';
export const GET_Q_SISE_SINISTER = 'GET_Q_SISE_SINISTER';
export const GET_Q_SISE_SINISTER_FULFILLED = 'GET_Q_SISE_SINISTER_FULFILLED';
export const GET_Q_SISE_SINISTER_PENDING = 'GET_Q_SISE_SINISTER_PENDING';
export const GET_USER_BY_ID_FULFILLED = 'GET_USER_BY_ID_FULFILLED';
export const GET_USER_BY_ID_PENDING = 'GET_USER_BY_ID_PENDING';
export const GET_FAQS_PENDING = 'GET_FAQS_PENDING';
export const GET_FAQS_REJECTED = 'GET_FAQS_REJECTED';
export const GET_USER_BY_ID_REJECTED = 'GET_USER_BY_ID_REJECTED';

export const GET_ALL_ACTIONS = 'GET_ALL_ACTIONS';

export const GET_CLAIM_CAUSES_REJECTED = 'GET_CLAIM_CAUSES_REJECTED';
export const GET_CLAIM_CAUSES_PENDING = 'GET_CLAIM_CAUSES_PENDING';
export const GET_CLAIM_CAUSES = 'GET_CLAIM_CAUSES';
export const GET_CLAIM_CAUSES_FULFILLED = 'GET_CLAIM_CAUSES_FULFILLED';

export const SISE_AUTH = 'SISE_AUTH';
export const SISE_AUTH_REJECTED = 'SISE_AUTH_REJECTED'
export const SISE_AUTH_PENDING = 'SISE_AUTH_PENDING'
export const SISE_AUTH_FULFILLED = 'SISE_AUTH_FULFILLED'
